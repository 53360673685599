import { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getMembers, selectProfileState } from "../profile/profileSlice";
import Sidebar from "../../component/Sidebar";
import {
  getFamilyData,
  getMyNumber,
  getMyNumberViews,
  MyNumber,
  selectMyNumberState,
  selectMyNumberViewPerAccount,
} from "./myNumberSlice";
import MyNumberRow from "./MyNumberRow";
import MyNumberLogsModal from "./MyNumberLogsModal";
import { useNavigate } from "react-router-dom";

function MyNumberUserDetailView() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selfAccount } = useAppSelector(selectProfileState);
  const { myNumberViews } = useAppSelector(selectMyNumberState);
  const views = useAppSelector(selectMyNumberViewPerAccount);
  const [myNumberMap, $myNumberMap] = useState<{ [id: string]: string }>({});
  const [isLogModalActive, $isLogModalActive] = useState(false);
  const [targetMyNumber, $targetMyNumber] = useState<MyNumber | undefined>(undefined);
  const [targetAccountName, $targetAccountName] = useState("");

  useEffect(() => {
    if (selfAccount?.id) {
      dispatch(getMembers({ accountId: selfAccount.id }));
      dispatch(getMyNumberViews({ accountId: selfAccount.id }));
      dispatch(getFamilyData({ account_id: selfAccount.id }));
    }
  }, [selfAccount]);
  const matchedView = useMemo(() => {
    if (!selfAccount?.id) return null;
    return views.find((a) => a.accountId === selfAccount.id);
  }, [views, selfAccount]);

  const handler = ({ id: myNumberId, action, name }: { id: number; action: string; name: string }) => {
    if (action === "browse") {
      dispatch(getMyNumber({ id: myNumberId })).then((res: any) => {
        $myNumberMap({
          ...myNumberMap,
          [`_${myNumberId}`]: res.payload,
        });
      });
    } else if (action === "register") {
      const dummyMyNumberView = (() => {
        const data = views.find((a) => a.accountId === matchedView?.accountId);
        if (!data) return;
        const matched = [data.self, data.spouse, ...data.dependent].find((d) => d.myNumber?.id === myNumberId);
        return matched?.myNumber;
      })();
      if (!dummyMyNumberView) return;
      const [entity, entityId] = (() => {
        if (dummyMyNumberView.spouse_id) return ["spouse", dummyMyNumberView.spouse_id];
        else if (dummyMyNumberView.dependent_id) return ["dependent", dummyMyNumberView.dependent_id];
        else return ["account", dummyMyNumberView.account_id];
      })();
      navigate(`/_/my_number/user/edit/${entity}/${entityId}`);
    } else if (action === "list_logs") {
      const matched = myNumberViews.find((v) => v.id === myNumberId);
      if (!matched) return;
      $targetMyNumber(matched);
      $targetAccountName(name);
      $isLogModalActive(true);
    }
  };

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"my_number"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">マイナンバー</h1>
        <main className="mt-3 py-4 px-md-2 bg-white">
          <Container>
            <Row>
              <Col>
                <Alert variant="info">マイナンバーについての操作履歴は全て記録されます。</Alert>
              </Col>
            </Row>
            {matchedView && (
              <div>
                <Row>
                  <Col>
                    <h2 className="Headline--section-sub">本人のマイナンバー</h2>
                    <MyNumberRow
                      id={matchedView.self.myNumber?.id ?? 0}
                      value={myNumberMap[`_${matchedView.self.myNumber?.id}`]}
                      name={matchedView.self.account?.name ?? ""}
                      status={matchedView.self.myNumber?.status ?? ""}
                      status_reason={matchedView.self.myNumber?.status_reason}
                      canReview={false}
                      handler={handler}
                    />
                  </Col>
                </Row>
                {matchedView.spouse.account || matchedView.dependent.some((d) => d.account) ? (
                  <Row className="--align-items-center mt-4">
                    <Col>
                      <h2 className="Headline--section-sub">配偶者・家族のマイナンバー</h2>
                      {matchedView.spouse?.account && matchedView.spouse?.myNumber && (
                        <MyNumberRow
                          id={matchedView.spouse.myNumber.id}
                          value={myNumberMap[`_${matchedView.spouse.myNumber.id}`]}
                          name={matchedView.spouse.account.name}
                          status={matchedView.spouse.myNumber.status}
                          status_reason={matchedView.spouse.myNumber.status_reason}
                          canReview={false}
                          handler={handler}
                          className="mb-1"
                        />
                      )}
                      {matchedView.dependent.length > 0 &&
                        matchedView.dependent.map((d) => {
                          if (!d.account || !d.myNumber) return null;
                          return (
                            <MyNumberRow
                              key={d.myNumber.id}
                              id={d.myNumber.id}
                              value={myNumberMap[`_${d.myNumber.id}`]}
                              name={d.account.name}
                              status={d.myNumber.status}
                              status_reason={d.myNumber.status_reason}
                              canReview={false}
                              handler={handler}
                              className="mb-1"
                            />
                          );
                        })}
                    </Col>
                  </Row>
                ) : null}
              </div>
            )}
            <MyNumberLogsModal
              isActive={isLogModalActive}
              name={targetAccountName}
              myNumber={targetMyNumber}
              onClose={() => $isLogModalActive(false)}
            />
          </Container>
        </main>
      </div>
    </div>
  );
}

export default MyNumberUserDetailView;
