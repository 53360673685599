import columnTypeChoicesJson from "../../column_type_choices.json";
import columnTypeMappingsJson from "../../column_type_mappings.json";
import dayjs from "dayjs";
import { RegularColumn, SectorRegularColumns } from "../client/clientValues";
import { store } from "../../app/store";
import { USER_TABLE_PREFIX } from "./profileValues";
import {
  TIME_REGEX,
  ZENKAKU_KANA_NUMBER_SPACE_REGEX,
  PHONE_NUMBER_REGEX,
  EMAIL_FORMAT_REGEX,
  ZENKAKU_KANA_SPACE_REGEX,
  POSTCODE_REGEX,
  BANK_ACCOUNT_NAME_KANA_REGEX,
} from "../../app/validator";

export type ProfileSubFieldType =
  | "string"
  | "longtext"
  | "number"
  | "float"
  | "boolean"
  | "postCode"
  | "bankNumber"
  | "bankBranchNumber"
  | "bankAccountNo"
  | "bankAccountNameKana"
  | "nameKana"
  | "addressKana"
  | "date"
  | "datetime"
  | "time"
  | "email"
  | "options"
  | "code"
  | "tagHandler"
  | "tableHandler"
  | "staticText"
  | "staticLine"
  | "staticLabel"
  | "file"
  | "checkbox"
  | "reference"
  | "phoneNumber"
  | "yearMonth";

export type FieldValue = string | number | boolean | string[] | null;
export type FieldValidationRule = [string, number | string, ProfileCondition?];

export type FieldValidator = (subFields: ProfileSubField[]) => {
  validated: boolean;
  subFields: ProfileSubField[];
};

type RecordData = {
  id: number;
  account_id?: number;
  created_at: string;
  updated_at: string;
  updated_by: number | null;
  valid_from: string;
  valid_to: string;
};

// コードの選択肢の場合、編集中レコードの終了日が選択肢の終了日の範囲内かチェックするため validTo を保持する
export type LabelValueOption = { label: string; value: string | number; validTo?: string };

export type ProfileFileElement = {
  key: string;
  name: string;
  object_name: string;
  mine?: string;
  size: number;
  created_at: number;
  created_by: number;
  deleted_at: number | null;
  deleted_by: number | null;
};

export type ProfileFile = {
  id: string;
  owner: number;
  files: ProfileFileElement[];
};

export type ProfileCondition = {
  [key: string]: FieldValue | ProfileCondition[];
};
export type Action = {
  type: "get_value" | "set_value" | "copy_value";
  from?: string;
  mapping: { [key: string]: string };
  only_same_index?: boolean;
};
export type Event = {
  type: "on_change" | "on_click";
  value?: FieldValue;
  button?: string;
  action: Action;
};

export type ProfileSubField = {
  id: string;
  type: ProfileSubFieldType;
  value: FieldValue;
  label?: FieldValue;
  count?: number;
  editable: boolean;
  errorMessage?: string;
  rules?: FieldValidationRule[];
  correlationRules?: FieldValidationRule[];
  labelValueOptions?: LabelValueOption[];
  entered: boolean;
  required: boolean;
  requiredConditions?: ProfileCondition;
  display?: boolean;
  displayConditions?: ProfileCondition;
  virtual_field?: boolean;
  isRelational?: boolean;
  defaultValue?: FieldValue;
  subFields?: ProfileSubField[];
  toDelete?: boolean;
  tag: string;
  tagGroupsToUse: number[];
  tagGroupIndex: number;
  minTagGroupsLength: number;
  maxTagGroupsLength: number;
  record: RecordData;
  highlighted?: boolean;
  termsKey?: string;
  pageIndex?: number;
  events?: Event[];
  referenceField?: boolean;
  keepValue?: boolean;
};

export type ProfileField = {
  fieldName: string;
  table: string;
  category: string;
  subFields: ProfileSubField[];
  labelMap: { [lang: string]: { [term: string]: string } };
  account: {
    id: number;
  };
};

export type ProfileSubFieldDiff = {
  value: any;
  recordId: number;
  subFieldName: string;
  toDelete?: boolean;
};

export type ValidDateMap = {
  [recordId: string]: { valid_from: string; valid_to: string };
};

export type NumberFormType = "number" | "text";

export const toProfileField = ({
  fieldName,
  base,
  response,
  columns,
}: {
  fieldName: string;
  base: { [k: string]: any };
  response?: { [key: string]: any };
  columns?: RegularColumn[];
}) => {
  columns =
    columns ??
    (() => {
      const { client } = store.getState();
      const _sectorRegularColumns = client.sectorRegularColumns as SectorRegularColumns;
      return _sectorRegularColumns[`${USER_TABLE_PREFIX}${base.table}`];
    })();

  const subFields = [] as ProfileSubField[];
  const tagHandlerFields = Object.keys(base.subFields)
    .filter(
      (subFieldName) =>
        base.subFields[subFieldName].type === "tagHandler" || base.subFields[subFieldName].type === "tableHandler"
    )
    .map((subFieldName) => base.subFields[subFieldName])
    .reduce((prev, current) => {
      return { ...prev, [current.tag]: current };
    }, {} as { [tagName: string]: ProfileSubField });
  const labelMap = {} as { [key: string]: string };
  const isTableView = Object.keys(base.subFields).some((s) => base.subFields[s].type === "tableHandler");
  for (const subFieldName in base.subFields) {
    const isIterableSubField = subFieldName.includes("_$N");
    const _subFieldName = !isIterableSubField ? subFieldName : subFieldName.replace("_$N", "");
    const column = columns.find(({ id }) => id === _subFieldName);
    if (column) labelMap[column.id] = column.label;
    const {
      type,
      isRelational,
      editable,
      required,
      defaultValue,
      rules,
      labelValueOptions,
      tag,
      tagGroupsToUse,
      tagGroupIndex,
      maxTagGroupsLength,
      minTagGroupsLength,
      virtual_field,
    } = base.subFields[subFieldName];

    const inputType = (() => {
      if (type) return type;
      return column?.input_type ?? "string";
    })();

    const thisSubField = {
      id: _subFieldName,
      value: response?.[_subFieldName] ?? "",
      required: required === true || column?.required || false,
      requiredConditions: column?.required_conditions ?? {},
      displayConditions: column?.display_conditions ?? {},
      entered: false,
      virtual_field: (() => {
        if (virtual_field === true) return true;
        return column?.virtual_field ?? false;
      })(),
      editable: (() => {
        if (editable === false || isTableView) return false;
        if (column?.reference_field) return false;
        return !column?.virtual_field;
      })(),
      rules: (() => {
        let _rules = [...rules];
        column?.rules.forEach((rule) => {
          _rules = [..._rules, rule];
        });
        return _rules;
      })(),
      type: inputType,
      isRelational: isRelational === true,
      defaultValue,
      labelValueOptions: (() => {
        if (inputType !== "options") return;
        if (labelValueOptions.length) return labelValueOptions;
        const choices = column?.options ?? userColumnChoices[`${USER_TABLE_PREFIX}${base.table}`][_subFieldName];
        return choices?.map((value) => ({ label: value, value })) ?? [];
      })(),
      label: (() => {
        if (subFieldName.indexOf("_code") !== -1 && inputType === "code" && response) {
          const sector = subFieldName.split("_code")[0];
          if (response[`${sector}_code`]) {
            return `（${response[`${sector}_code`]}）${response[`${sector}_name`]}`;
          } else {
            return "";
          }
        }
      })(),
      count: 0,
      termsKey: `${USER_TABLE_PREFIX}${fieldName}__${_subFieldName}`,
      tag: tag ?? "untagged",
      tagGroupsToUse: tagGroupsToUse ?? [],
      tagGroupIndex: tagGroupIndex ?? 0,
      maxTagGroupsLength: maxTagGroupsLength ?? 3,
      minTagGroupsLength: minTagGroupsLength ?? 1,
      record: {
        id: response?.id ?? -1,
        account_id: response?.account_id ?? -1,
        created_at: response?.created_at ?? "",
        updated_at: response?.updated_at ?? "",
        updated_by: response?.updated_by ?? null,
        valid_from: response?.valid_from ?? "",
        valid_to: response?.valid_to ?? "",
      },
    };
    if (!isIterableSubField) {
      subFields.push(thisSubField);
      continue;
    }
    if (!tagHandlerFields[tag]) {
      throw new Error(`tagHandler subField is required for tag ${tag}`);
    }
    for (let i = 1, max = tagHandlerFields[tag].maxTagGroupsLength; i <= max; i++) {
      const indexedId = subFieldName.replace("$N", `${i}`);
      const useResponse = i === 1 && !!response;
      subFields.push({
        ...thisSubField,
        id: indexedId,
        value:
          useResponse && response?.[_subFieldName] !== null && response?.[_subFieldName] !== undefined
            ? response?.[_subFieldName]
            : "",
        tagGroupIndex: i,
        record: {
          id: useResponse ? response?.id : -1 * i,
          created_at: useResponse ? response?.created_at : "",
          updated_at: useResponse ? response?.updated_at : "",
          updated_by: useResponse ? response?.updated_by : null,
          valid_from: useResponse ? response?.valid_from : dayjs().format("YYYY-MM-DD"),
          valid_to: useResponse ? response?.valid_to : "",
        },
      });
    }
  }
  // label補完
  if (fieldName.endsWith("_history"))
    labelMap["valid_to_fixed"] = columns.find(({ id }) => id === "valid_to")?.label ?? "";
  return {
    fieldName,
    category: base.category,
    table: base.table,
    subFields,
    labelMap: { ja: { ...labelMap, ...(base.labelMap?.ja ?? []) } },
    account: {
      id: response?.account_id ?? -1,
    },
  };
};

export const generateValidatorBundler = (subFieldsData: { [subFieldName: string]: any }) => {
  return (subFields: ProfileSubField[]) => {
    subFieldsData = Object.keys(subFieldsData).reduce((prev, subFieldName) => {
      const _subField = subFields.find(({ id }) => id === subFieldName);
      return { ...prev, [subFieldName]: { type: _subField?.type } };
    }, {} as { [subFieldName: string]: any });
    return generateValidator(subFieldsData)(subFields);
  };
};

export const generateProfileFieldsBundler = (fieldName: string, base: { [k: string]: any }) => {
  return (responseResults: { [k: string]: any }[], columns?: RegularColumn[]): ProfileField[] =>
    responseResults.map((r) => toProfileField({ fieldName, base, response: r, columns }));
};

export const generateMultipleProfileFieldsBundler = (
  fieldName: string,
  base: { [k: string]: any },
  handlerName: string,
  tagName: string
) => {
  return (responseResults: { [k: string]: any }[], columns?: RegularColumn[]): ProfileField[] => {
    /*
      レスポンスの数だけ ProfileSubField を複製させるために値を補完
    */
    const _base = {
      ...base,
      subFields: (() => {
        const subFields = {} as { [key: string]: any };
        for (const key in base.subFields) {
          if (base.subFields[key].type !== "tagHandler" || base.subFields[key].type !== "tableHandler") {
            subFields[key] = {
              ...base.subFields[key],
              maxTagGroupsLength: base.subFields[key].maxTagGroupsLength ?? responseResults.length,
            };
            continue;
          }
        }
        return subFields;
      })(),
    };
    const profileFields = generateProfileFieldsBundler(fieldName, _base)(responseResults, columns);
    /*
        この段階では同じアカウントに紐づくレコードが別々の ProfileField になっている
        これらの ProfileSubFields を１つの ProfileField に集約することが目標。
        */
    const assembled: ProfileField[] = [];
    profileFields.forEach((profileField) => {
      let current: ProfileField;
      const correspondingProfileField = assembled.find((_) => _.account.id === profileField.account.id);
      if (correspondingProfileField) {
        current = correspondingProfileField;
      } else {
        current = { ...profileField };
        assembled.push(current);
      }
      const handler = current.subFields.find(
        (_) => (_.type === "tagHandler" || _.type === "tableHandler") && _.tag === tagName
      );
      if (!handler) return;
      const nextTagGroupIndex = handler.tagGroupsToUse.length > 0 ? Math.max(...handler.tagGroupsToUse) + 1 : 1;
      const nextTagGroupsToUse = [...handler.tagGroupsToUse, nextTagGroupIndex];
      const _handler = {
        ...handler,
        tagGroupsToUse: nextTagGroupsToUse,
      };

      handler.tagGroupsToUse = nextTagGroupsToUse;
      if (nextTagGroupIndex > 1) {
        /*
          一旦 dependent_1_name のように 1 つめに対応する SubField に
          レコードの値が入っているので、 dependent_${nextTagGroupIndex}_name のように
          まとめたときの連番のフィールドに移し替える。
        */
        const subFieldsWithValues = profileField.subFields
          .filter((_) => _.tag === _handler.tag)
          .filter((_) => _.type !== handlerName)
          .filter((_) => _.tagGroupIndex === 1);
        subFieldsWithValues.forEach((thisSubField) => {
          const toMove = current.subFields.find(
            (_) => _.id === thisSubField.id.replace(/_1$/, `_${nextTagGroupIndex}`)
          );
          if (!toMove) return;
          toMove.value = thisSubField.value;
          toMove.record = { ...thisSubField.record };
        });
      }
    });
    return assembled;
  };
};

export const diffCollector = (subFields: ProfileSubField[], validDateMap: ValidDateMap): ProfileSubFieldDiff[] => {
  const map = [] as ProfileSubFieldDiff[];
  const getValue = (subField: ProfileSubField) => {
    let value = subField.value;
    // float型の場合はstringからnumberに変換
    if (subField.type === "float" && subField.value !== null && subField.value !== "") {
      value = +subField.value;
    }
    if (subField.defaultValue !== undefined && !value && value !== 0) {
      value = subField.defaultValue;
    }
    return value;
  };
  subFields.forEach((subField) => {
    const isValidFromWillBeUpdated = validDateMap[subField.record.id]?.valid_from;
    const isDisplay = isDisplayField(subField, subFields);
    if (subField.editable && subField.value && !isDisplay && subField.type !== "file") {
      subField = { ...subField, entered: true, value: null };
      subFields = [...subFields.filter((s) => s.id !== subField.id), subField];
    }
    if (!isValidFromWillBeUpdated && subField.editable && subField.entered) {
      const diff = {
        subFieldName: subField.id,
        value: getValue(subField),
        recordId: subField.record.id,
        toDelete: subField.toDelete,
      };
      map.push(diff);
    }
  });
  for (const recordId in validDateMap) {
    // recordId が -1 かつ validDateMap が 1件（履歴追加）　または　valid_from が含まれている（開始日の編集）場合は、新しいレコードを post する
    if ((+recordId === -1 && Object.keys(validDateMap).length === 1) || validDateMap[recordId].valid_from) {
      const subFieldsToCopy = [...subFields]
        .filter((s) => s.record.id === +recordId)
        .filter((s) => s.editable || s.required) //  入力はさせたくないが post 時に必要な項目は editable=false で require=true とする
        .filter((s) => s.id !== "valid_from" && s.id !== "valid_to")
        .filter((s) => s.value !== null && s.value !== "");
      const _recordId = +recordId > 0 ? -1 * +recordId * 2 : +recordId;
      subFieldsToCopy.map((subField) => {
        map.push({
          subFieldName: subField.id,
          value: getValue(subField),
          recordId: _recordId,
        });
      });
      // コピーしたレコードに valid_from, valid_to を指定
      map.push({
        subFieldName: "valid_from",
        value: validDateMap[recordId].valid_from,
        recordId: _recordId,
      });
      map.push({
        subFieldName: "valid_to",
        value: validDateMap[recordId].valid_to,
        recordId: _recordId,
      });
      // 既存レコードの編集の場合、現在のレコードを delete する
      if (+recordId > 0) {
        map.push({
          subFieldName: "_",
          value: "",
          recordId: +recordId,
          toDelete: true,
        });
      }
    }
    // valid_to は変更可能
    else if (validDateMap[recordId].valid_to) {
      map.push({
        subFieldName: "valid_to",
        value: validDateMap[recordId].valid_to,
        recordId: +recordId,
      });
    }
  }
  return map;
};

export const requestBodyBuilderMap = {
  put: (body: { [field: string]: FieldValue }, recordId: number) => {
    return {
      ...body,
      id: recordId,
    };
  },
  post: (body: { [field: string]: FieldValue }, login_code: string, valid_from: string) => {
    return {
      ...body,
      login_code,
      valid_from,
    };
  },
};

export const generateValidator = (subFieldsData: { [subFieldName: string]: any }): FieldValidator => {
  const subFieldValidators = {} as {
    [subFieldName: string]: Function;
  };

  /*
    [[false,"入力してください"],[true,""] のような
    ProfileSubField のルールごとの検証結果を統合する
    */
  const reducer = (array: [boolean, string][] = []) =>
    array.reduce(
      (prev, current) => {
        return [
          prev[0] && current[0],
          (() => {
            if (!current[1]) return prev[1]; // メッセージがない場合は前のメッセージを引き継ぐ
            if (!prev[1]) return current[1]; // 前のメッセージがない場合は今のメッセージを返す
            return [prev[1], current[1]].join(" / "); // 結合して返す
          })(),
        ];
      },
      [true, ""]
    );
  const emptyValidator = () => [true, ""];

  for (const subFieldName in subFieldsData) {
    const stringRuleCheck = (v: string, opr = "", opd = 0): [boolean, string] => {
      const isEmpty = v === null || v === "";
      switch (opr) {
        case "<":
          return [isEmpty || v.length < opd, isEmpty || v.length < opd ? "" : "文字数が超過しています"];
        case ">":
          return [isEmpty || v.length > opd, isEmpty || v.length > opd ? "" : "文字数が不足しています"];
        case "<=":
          return [isEmpty || v.length <= opd, isEmpty || v.length <= opd ? "" : `${opd} 文字以下で入力してください`];
        case ">=":
          return [isEmpty || v.length >= opd, isEmpty || v.length >= opd ? "" : `${opd} 文字以上入力してください`];
        case "isFloat":
          return [!Number.isNaN(+v), !Number.isNaN(+v) ? "" : "数値を入力してください"];
        default:
          return [true, ""];
      }
    };
    switch (subFieldsData[subFieldName].type) {
      case "string":
      case "longtext":
        subFieldValidators[subFieldName] = ({
          value,
          rules = [],
          required,
        }: {
          value: string;
          rules: FieldValidationRule[];
          required: boolean;
        }) => {
          if (required && !value) return [false, "入力してください"];
          return reducer(rules.map(([opr, opd]) => stringRuleCheck(value, `${opr}`, +opd)));
        };

        break;
      case "date":
      case "datetime":
        const dateRuleCheck = (v: any, opr = "", opd = ""): [boolean, string] => {
          v = subFieldsData[subFieldName].type === "date" ? dayjs(v).format("YYYY-MM-DD") : v;
          switch (opr) {
            case "<":
              return [v < opd, v < opd ? "" : `${opd} より前の日付を入力してください`];
            case ">":
              return [v > opd, v > opd ? "" : `${opd} より後の日付を入力してください`];
            case "<=":
              return [v <= opd, v <= opd ? "" : `${opd} 以前の日付を入力してください`];
            case ">=":
              return [v >= opd, v >= opd ? "" : `${opd} 以降の日付を入力してください`];
            case "=":
              return [v == opd, v == opd ? "" : `${opd} に設定してください`];
            default:
              return [true, ""];
          }
        };
        subFieldValidators[subFieldName] = ({
          value,
          rules = [],
          required,
        }: {
          value: string;
          rules: FieldValidationRule[];
          required: boolean;
        }) => {
          const valid = required ? value !== "" && value !== null : true;
          return !valid
            ? [valid, "日付を選択してください"]
            : reducer(rules.map(([opr, opd]) => dateRuleCheck(value, `${opr}`, `${opd}`)));
        };
        break;
      case "postCode":
        subFieldValidators[subFieldName] = ({ value, required }: { value: string; required: boolean }) => {
          const isEmpty = value === null || value === "";
          if (required && isEmpty) {
            return [false, "入力してください"];
          } else {
            const valid = isEmpty || POSTCODE_REGEX.test(value);
            return [valid, valid ? "" : "xxx-xxxx の形式で入力してください"];
          }
        };
        break;
      case "bankNumber":
        subFieldValidators[subFieldName] = ({ value, required }: { value: string; required: boolean }) => {
          const isEmpty = value === null || value === "";
          if (required && isEmpty) {
            return [false, "入力してください"];
          } else {
            const valid = isEmpty || /^[0-9]{4}$/.test(value);
            return [valid, valid ? "" : "4桁の半角数字で入力してください"];
          }
        };
        break;
      case "bankBranchNumber":
        subFieldValidators[subFieldName] = ({ value, required }: { value: string; required: boolean }) => {
          const isEmpty = value === null || value === "";
          if (required && isEmpty) {
            return [false, "入力してください"];
          } else {
            const valid = isEmpty || /^[0-9]{3}$/.test(value);
            return [valid, valid ? "" : "3桁の半角数字で入力してください"];
          }
        };
        break;
      case "bankAccountNo":
        subFieldValidators[subFieldName] = ({ value, required }: { value: string; required: boolean }) => {
          const isEmpty = value === null || value === "";
          if (required && isEmpty) {
            return [false, "入力してください"];
          } else {
            const valid = isEmpty || /^[0-9]{7}$/.test(value);
            return [valid, valid ? "" : "7桁の半角数字で入力してください"];
          }
        };
        break;
      case "bankAccountNameKana":
        subFieldValidators[subFieldName] = ({
          value,
          required,
          rules,
        }: {
          value: string;
          required: boolean;
          rules: FieldValidationRule[];
        }) => {
          const isEmpty = value === null || value === "";
          if (required && isEmpty) {
            return [false, "入力してください"];
          } else {
            const valid = isEmpty || BANK_ACCOUNT_NAME_KANA_REGEX.test(value);
            if (!valid) {
              return [false, "英数字 カタカナ 記号（, . - 「 」( ) / \\）スペースのみで入力してください"];
            } else return reducer(rules.map(([opr, opd]) => stringRuleCheck(value, `${opr}`, +opd)));
          }
        };
        break;
      case "nameKana":
        subFieldValidators[subFieldName] = ({
          value,
          required,
          rules,
        }: {
          value: string;
          required: boolean;
          rules: FieldValidationRule[];
        }) => {
          const isEmpty = value === null || value === "";
          if (required && isEmpty) {
            return [false, "入力してください"];
          } else {
            const valid = isEmpty || ZENKAKU_KANA_SPACE_REGEX.test(value);
            if (!valid) {
              return [valid, "全角カナ スペースのみで入力してください"];
            } else return reducer(rules.map(([opr, opd]) => stringRuleCheck(value, `${opr}`, +opd)));
          }
        };
        break;
      case "addressKana":
        subFieldValidators[subFieldName] = ({
          value,
          required,
          rules,
        }: {
          value: string;
          required: boolean;
          rules: FieldValidationRule[];
        }) => {
          const isEmpty = value === null || value === "";
          if (required && isEmpty) {
            return [false, "入力してください"];
          } else {
            const valid = isEmpty || ZENKAKU_KANA_NUMBER_SPACE_REGEX.test(value);
            if (!valid) {
              return [valid, "全角カタカナ 数字 - スペースのみで入力してください"];
            } else return reducer(rules.map(([opr, opd]) => stringRuleCheck(value, `${opr}`, +opd)));
          }
        };
        break;
      case "phoneNumber":
        subFieldValidators[subFieldName] = ({ value, required }: { value: string; required: boolean }) => {
          const isEmpty = value === null || value === "";
          if (required && isEmpty) {
            return [false, "入力してください"];
          } else {
            const valid = isEmpty || PHONE_NUMBER_REGEX.test(value);
            return [valid, valid ? "" : "半角数字 記号 - のみで入力してください"];
          }
        };
        break;
      case "number":
        const numberRuleCheck = (v: any, opr = "", opd = 0): [boolean, string] => {
          switch (opr) {
            case "<":
              return [v === null || v < opd, v === null || v < opd ? "" : `${opd} より小さい数を入力してください`];
            case ">":
              return [v === null || v > opd, v === null || v > opd ? "" : `${opd} より大きい数を入力してください`];
            case "<=":
              return [v === null || v <= opd, v === null || v <= opd ? "" : `${opd} 以下の数を入力してください`];
            case ">=":
              return [v === null || v >= opd, v === null || v >= opd ? "" : `${opd} 以上の数を入力してください`];
            default:
              return [true, ""];
          }
        };
        subFieldValidators[subFieldName] = ({
          value,
          rules = [],
          required,
        }: {
          value: number;
          rules: FieldValidationRule[];
          required: boolean;
        }) => {
          if (required && typeof value !== "number") return [false, "数値を入力してください"];
          return reducer(rules.map(([opr, opd]) => numberRuleCheck(value, `${opr}`, +opd)));
        };

        break;
      case "boolean":
        subFieldValidators[subFieldName] = ({
          value,
          rules = [],
          required,
        }: {
          value: number;
          rules: FieldValidationRule[];
          required: boolean;
        }) => {
          if (required) {
            return [value, !!value ? "" : "選択してください"];
          } else {
            return [true, ""];
          }
        };
        break;
      case "email":
        subFieldValidators[subFieldName] = ({ value, required }: { value: string; required: boolean }) => {
          const isEmpty = value === "" || value === null;
          if (required) {
            const errMessage = isEmpty ? "入力してください" : "メールアドレスの入力形式が不正です";
            const valid = !isEmpty && value.match(EMAIL_FORMAT_REGEX);
            return [valid, valid ? "" : errMessage];
          } else {
            const valid = isEmpty || value.match(EMAIL_FORMAT_REGEX);
            return [valid, valid ? "" : "メールアドレスの入力形式が不正です"];
          }
        };

        break;
      case "options":
      case "yearMonth":
        const optionsRuleCheck = (v: any, opr = "", opd = ""): [boolean, string] => {
          switch (opr) {
            case "=":
              return [opd === v, opd === v ? "" : `「${opd}」を選択してください`];
            case "!=":
              return [opd !== v, opd !== v ? "" : `「${opd}」以外を選択してください`];
            default:
              return [true, ""];
          }
        };
        subFieldValidators[subFieldName] = ({
          value,
          rules = [],
          required,
          labelValueOptions,
        }: {
          value: string;
          rules: FieldValidationRule[];
          required: boolean;
          labelValueOptions: LabelValueOption[];
        }) => {
          const valid = required ? labelValueOptions.some((_) => _.value === value) : true;
          return !valid
            ? [valid, "選択してください"]
            : reducer(rules.map(([opr, opd]) => optionsRuleCheck(value, `${opr}`, `${opd}`)));
        };
        break;
      case "code":
        subFieldValidators[subFieldName] = ({
          value,
          rules = [],
          required,
          labelValueOptions,
        }: {
          value: string;
          rules: FieldValidationRule[];
          required: boolean;
          labelValueOptions: LabelValueOption[];
        }) => {
          if (required && labelValueOptions.length === 0) {
            return [false, "選択肢がありません"];
          } else if (required) {
            const valid = value !== "" && labelValueOptions.some((_) => _.value === value);
            return [valid, valid ? "" : "選択してください"];
          } else {
            return [true, ""];
          }
        };
        break;
      case "time":
        const timeRuleCheck = (v: string, opr: string, opd: string): [boolean, string] => {
          switch (opr) {
            case "<":
              return [v < opd, v < opd ? "" : `${opd} より前の時刻を入力してください`];
            case ">":
              return [v > opd, v > opd ? "" : `${opd} より後の時刻を入力してください`];
            case "<=":
              return [v <= opd, v <= opd ? "" : `${opd} 以前の時刻を入力してください`];
            case ">=":
              return [v >= opd, v >= opd ? "" : `${opd} 以降の時刻を入力してください`];
            default:
              return [true, ""];
          }
        };
        subFieldValidators[subFieldName] = ({
          value,
          required,
          rules,
        }: {
          value: string;
          required: boolean;
          rules: FieldValidationRule[];
        }) => {
          const isEmpty = value === null || value === "";
          if (required && isEmpty) {
            return [false, "時刻を選択してください"];
          } else {
            const valid = isEmpty || TIME_REGEX.test(value);
            if (!valid) {
              return [false, "時刻の形式が不正です"];
            } else return reducer(rules.map(([opr, opd]) => timeRuleCheck(value, `${opr}`, `${opd}`)));
          }
        };
        break;
      case "checkbox":
        subFieldValidators[subFieldName] = ({
          value,
          required,
          rules,
        }: {
          value: string;
          required: boolean;
          rules: FieldValidationRule[];
        }) => {
          const isEmpty = value === null || value.length === 0;
          if (required && isEmpty) {
            return [false, "選択してください"];
          }
          return [true, ""];
        };
        break;
      case "file":
        subFieldValidators[subFieldName] = ({
          value,
          required,
          rules,
        }: {
          value: string;
          required: boolean;
          rules: FieldValidationRule[];
        }) => {
          if (required && +value === 0) {
            return [false, "ファイルをアップロードしてください"];
          }
          return [true, ""];
        };
        break;
      case "float":
        subFieldValidators[subFieldName] = ({ value, required }: { value: string; required: boolean }) => {
          if (required && value === "") {
            return [false, "入力してください"];
          }
          const valid = value === "" || !Number.isNaN(+value);
          return [valid, valid ? "" : "数値を入力してください"];
        };
        break;
      default:
        subFieldValidators[subFieldName] = emptyValidator;
        break;
    }
  }
  return (subFields: ProfileSubField[]) => {
    let validated = true;
    const nextSubFields = subFields.map((sf) => {
      // editable:false の場合は何もしない
      if (!sf.editable) {
        return {
          ...sf,
          errorMessage: "",
        };
      }
      const validator =
        subFieldValidators[sf.id] || subFieldValidators[sf.id.replace(/(_[1-9]+)/, "_$N")] || emptyValidator;
      const required = isRequiredField(sf, subFields);
      // ファイル項目の場合は、ファイルの数を value にセットする
      const value = sf.type === "file" ? sf.count : sf.value;
      const [_validated, errorMessage] = validator({
        value,
        rules: getRules(sf, subFields),
        required,
        labelValueOptions: sf.labelValueOptions,
      }); // required をここで渡すようにする
      if (!_validated) validated = false;
      return {
        ...sf,
        errorMessage: _validated ? "" : errorMessage,
      };
    });
    return {
      validated,
      subFields: nextSubFields,
    };
  };
};

export const userColumnChoices = (() => {
  // 2つのjsonファイル取得
  const columnTypeMappings = columnTypeMappingsJson as {
    [table: string]: { [column: string]: string };
  };
  const columnTypeChoices = columnTypeChoicesJson as {
    [key: string]: { [label: string]: string }[];
  };
  let userColumnChoices = {} as {
    [table: string]: { [column: string]: string[] };
  };
  for (const tableKey in columnTypeMappings) {
    // ユーザデータ系のみに絞る
    if (!tableKey.startsWith("u_")) continue;
    const choiceKeys = columnTypeMappings[tableKey];
    const tableName = `profile_${tableKey}`;
    let columnChoice = {} as { [column: string]: string[] };
    for (const columnName in choiceKeys) {
      // カラムに対する選択肢の配列を取得
      const choiceKey = choiceKeys[columnName];
      const choices = columnTypeChoices[choiceKey]?.map((choice) => choice.label);
      columnChoice = { ...columnChoice, [columnName]: choices };
    }
    // テーブル毎にまとめる
    userColumnChoices = { ...userColumnChoices, [tableName]: columnChoice };
  }
  return userColumnChoices;
})();

export const isRequiredField = (subField: ProfileSubField, subFields: ProfileSubField[]) => {
  if (subField.required) return true;
  if (!subField.requiredConditions || Object.keys(subField.requiredConditions).length === 0) return false;
  return isFulfilled(subField, subField.requiredConditions, subFields);
};

export const isDisplayField = (subField: ProfileSubField, subFields: ProfileSubField[]) => {
  const { display, displayConditions, requiredConditions } = subField;
  // display が false の場合は表示しない
  if (display === false) return false;
  // displayConditions, requiredCondition ともにない場合は表示
  const hasDisplayConditions = displayConditions && Object.keys(displayConditions).length > 0;
  const hasRequiredConditions = requiredConditions && Object.keys(requiredConditions).length > 0;
  if (!hasDisplayConditions && !hasRequiredConditions) return true;

  // displayConditions があって条件を満たす場合は表示
  if (hasDisplayConditions && isFulfilled(subField, displayConditions, subFields)) return true;
  // requiredCondition があって条件を満たす場合は表示
  if (hasRequiredConditions && isFulfilled(subField, requiredConditions, subFields)) return true;
  // 上記以外は非表示
  return false;
};

const isFulfilled = (
  subField: ProfileSubField,
  conditions: ProfileCondition,
  subFields: ProfileSubField[]
): boolean => {
  return Object.keys(conditions).every((key) => {
    const conditionValue = conditions?.[key];
    if (key === "or" && Array.isArray(conditionValue)) {
      let cond = false;
      for (const orCondition of conditionValue) {
        if (typeof orCondition !== "object") continue;
        cond = cond || isFulfilled(subField, orCondition, subFields);
      }
      return cond;
    }
    let role = "normal";
    if (key.includes("__")) {
      const splited = key.split("__");
      key = splited[0];
      role = splited[1];
    }
    let label = "";
    if (key.includes("**")) {
      const splited = key.split("**");
      if (splited[1] === "LABEL") {
        key = splited[0];
        label = splited[1];
      }
    }
    // tagがdpの場合idにインデックスが付与されているので、keyを変更する
    const _key = subField.tag === "dp_" ? `${key}_${subField.tagGroupIndex}` : key;

    const targetSubField = subFields.find((_) => _.id === _key);
    if (!targetSubField) return false;

    // keyにラベルを参照するように指定されている場合は、valueにlabelをセットする
    const value = label ? targetSubField?.label : targetSubField?.value;
    // 条件の項目が日付型の場合
    if (typeof value === "string" && dayjs(value, "YYYY-MM-DD").isValid() && typeof conditionValue === "string") {
      if (role == "normal") return value === conditionValue;
      if (role === "nin") return !conditionValue.includes(value);
      if (role === "lt") return dayjs(value) < dayjs(conditionValue);
      if (role === "lte") return dayjs(value) <= dayjs(conditionValue);
      if (role === "gt") return dayjs(value) > dayjs(conditionValue);
      if (role === "gte") return dayjs(value) >= dayjs(conditionValue);
      // 条件の項目が数値型の場合
    } else if (typeof value === "number" && typeof conditionValue === "number") {
      if (role == "normal") return value === conditionValue;
      if (role === "lt") return value < conditionValue;
      if (role === "lte") return value <= conditionValue;
      if (role === "gt") return value > conditionValue;
      if (role === "gte") return value >= conditionValue;
    } else {
      // 値が一致するかチェック
      if (role == "normal") return conditionValue === value;
      // 条件の文字列を含むかチェック
      if (role == "contains" && typeof value === "string" && typeof conditionValue === "string")
        return value.includes(conditionValue);
      // 値が一致しないかチェック
      if (role === "nin" && Array.isArray(conditionValue)) return !conditionValue.some((v) => v === value);
      // in条件の場合は配列に含まれているかチェック
      if (role === "in" && Array.isArray(conditionValue) && typeof value === "string")
        return value && conditionValue?.some((v) => v === value);
    }
    return false;
  });
};

const getRules = (subField: ProfileSubField, subFields: ProfileSubField[]) => {
  let { rules, correlationRules, type } = subField;

  rules = (rules ?? []).reduce((prev, current) => {
    const [oprator, ruleValue, conditions] = current;
    if (conditions && !isFulfilled(subField, conditions, subFields)) return prev;
    return [...prev, [oprator, ruleValue]];
  }, [] as FieldValidationRule[]);

  // 同じオペレータに対して複数値が設定される可能性があるため、
  // {">": ["2023-10-01", "2023-10-02"]} のような形でまとめる
  let operatorRules = rules.reduce(
    (prev, current) => ({ ...prev, [current[0]]: [...(prev[current[0]] ?? []), current[1]] }),
    {} as { [key: string]: (number | string)[] }
  );
  // 相関チェックの値を実際の値に置き換える
  for (const rule of correlationRules ?? []) {
    const [condition, key, conditions] = rule;
    if (conditions && !isFulfilled(subField, conditions, subFields)) continue;
    const targetSubField = subFields.find((_) => _.id === key);
    if (!targetSubField) continue;

    let value = targetSubField.value;
    // 値がなければスキップ
    if (!value) continue;
    // 作成時入力項目を除き、表示されていなければスキップ
    if (targetSubField.pageIndex !== -1 && !isDisplayField(targetSubField, subFields)) continue;
    if (typeof value === "string" || typeof value === "number") {
      // date型の場合はフォーマットを変更
      if (type === "date") value = dayjs(value).format("YYYY-MM-DD");
      operatorRules = {
        ...operatorRules,
        [condition]: [...(operatorRules[condition] ?? []), value],
      };
    }
  }
  // 同じオペレータに対して複数値が設定されている場合代表値を取得する
  return Object.entries(operatorRules).map(([opr, arr]) => {
    if (opr === ">" || opr === ">=") return [opr, arr.sort()[arr.length - 1]];
    if (opr === "<" || opr === "<=") return [opr, arr.sort()[0]];
    return [opr, arr[0]]; // それ以外は最初の値を取得 必要になったら実装追加
  });
};

export const copyValues = (mapping: { [key: string]: string }, subFields: ProfileSubField[], index: string | null) => {
  const correctedMapping = Object.keys(mapping).reduce((prev, current) => {
    return { ...prev, [index === null ? current : `${current}::${index}`]: mapping[current] };
  }, {} as { [key: string]: string });
  const mappingKeys = Object.keys(correctedMapping);
  return subFields.map((sf) => {
    const subFieldId = index ? sf.id : sf.id.split("::")[0];
    if (!mappingKeys.includes(subFieldId)) return sf; // マッピング対象ではない場合はスルー
    if (!sf.editable) return sf; // 編集不可の場合はスルー

    const mappingValue = correctedMapping[subFieldId];
    const keys = Array.from(mappingValue.matchAll(/{([^{}]*)}/g));
    let value = null;
    if (keys.length === 0) {
      value = subFields.find((_) => _.id === mappingValue)?.value;
    } else {
      const params = keys.reduce((prev, current) => {
        const value = subFields.find((_) => _.id === current[1])?.value;
        return { ...prev, [current[1]]: value ? `${value}` : "" };
      }, {} as { [key: string]: string });
      value = Object.keys(params).reduce(
        (prev, current) => prev.replace(`{${current}}`, params[current] ?? ""),
        mappingValue
      );
    }
    return value ? { ...sf, value } : sf;
  });
};
