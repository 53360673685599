import { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Uploader, { DecodedFileData } from "../../component/Uploader";
import Sidebar from "../../component/Sidebar";
import { MY_NUMBER_REGEX } from "../../app/validator";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  selectMyNumberState,
  getMyNumberSettings,
  getMyNumberViews,
  selectMyNumberViewPerAccount,
  getFamilyData,
  getMyNumber,
} from "./myNumberSlice";
import { selectLayoutState } from "../layout/layoutSlice";
import ModalDialog from "../../component/ModalDialog";
import { selectProfileState } from "../profile/profileSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { useNavigate, useParams } from "react-router-dom";
import PhotoCapturer from "../../component/PhotoCapturer";

function MyNumberDetail() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { accountId, entity, entityId } = useParams();
  const { myNumberViews, textForEmployee, textForFamily } = useAppSelector(selectMyNumberState);
  const { selfAccount } = useAppSelector(selectProfileState);
  const { isTouchDevice } = useAppSelector(selectLayoutState);
  const views = useAppSelector(selectMyNumberViewPerAccount);
  const [currentValue, $currentValue] = useState("");
  const [repeatedName, $repeatedName] = useState("");
  const [activeModal, $activeModal] = useState("");
  const [entered, $entered] = useState({
    repeatedName: false,
    currentValue: false,
  });
  const [policyConfirmed, $policyConfirmed] = useState(false);
  const [files, $files] = useState([] as DecodedFileData[]);
  const isNumberValid = useMemo(() => {
    return MY_NUMBER_REGEX.test(currentValue);
  }, [currentValue]);

  const _accountId = useMemo(() => {
    // 一般ユーザーの場合 path からは渡されない
    return accountId ? +accountId : selfAccount?.id;
  }, [accountId, selfAccount]);

  const onFileLoad = async (file: DecodedFileData) => {
    $files([...files, file]);
  };
  const cancel = () => {
    navigate(-1);
  };
  const commit = () => {
    $activeModal("");
  };
  const deleteFile = (index: number) => {
    $files(files.filter((_, i) => i !== index));
  };
  const [entityIdName, relationship] = useMemo(() => {
    if (entity === "account") return ["account_id", "社員本人"];
    else if (entity === "dependent") return ["dependent_id", "家族"];
    else if (entity === "spouse") return ["spouse_id", "配偶者"];
    else return ["", ""];
  }, [entity, entityId]);

  const onSave = (result: DecodedFileData) => {
    $files([...files, result]);
  };

  useEffect(() => {
    if (entityIdName && entityId) {
      dispatch(
        getMyNumberViews({
          [entityIdName]: +entityId,
        })
      );
      if (entity !== "account") {
        if (_accountId) {
          dispatch(getFamilyData({ account_id: _accountId }));
        } else if (selfAccount) {
          dispatch(getFamilyData({ account_id: selfAccount.id }));
        }
      }
    }
  }, [entityIdName, entityId, selfAccount]);
  const targetData = useMemo(() => {
    if (!_accountId || !entity || !entityIdName || !entityId) {
      return;
    }
    const data = views.find((view) => view.accountId === _accountId);
    return entity === "account"
      ? data?.self
      : entity === "spouse"
      ? data?.spouse
      : data?.dependent.find((d) => d.myNumber?.dependent_id === +entityId);
  }, [myNumberViews, entity, entityIdName, entityId]);
  const isValid = useMemo(() => {
    if (!targetData?.myNumber) return false;
    if (targetData.myNumber.status === "unregistered" || targetData.myNumber.status === "rejected") {
      return isNumberValid && files.length > 0 && policyConfirmed;
    } else if (targetData.myNumber.status === "reviewing") {
      return repeatedName === targetData.account.name;
    }
    return false;
  }, [targetData, isNumberValid, files, repeatedName, policyConfirmed]);
  const operationLabel = useMemo(() => {
    if (targetData?.myNumber) {
      if (targetData.myNumber.status === "unregistered") return "登録";
      $policyConfirmed(true);
      if (targetData.myNumber.status === "reviewing") return "承認";
      else if (targetData.myNumber.status === "rejected") return "再登録";
      else if (targetData.myNumber.status === "done") return "確認";
      return "";
    } else {
      return "";
    }
  }, [targetData]);
  useEffect(() => {
    if (!textForEmployee) dispatch(getMyNumberSettings());
  }, [textForEmployee]);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"my_number"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">マイナンバー - {operationLabel}</h1>
        <main className="mt-3 py-4 px-md-2 bg-white">
          {targetData && (
            <Container>
              <Row className="--align-items-center">
                <Col md="3" className="--bold">
                  対象者
                </Col>
                <Col md="9">{targetData.account.name}</Col>
              </Row>
              <Row className="--align-items-center mt-2">
                <Col md="3" className="--bold">
                  関係性
                </Col>
                <Col md="9">{relationship}</Col>
              </Row>
              <Row className="--align-items-center mt-2">
                <Col md="3" className="--bold">
                  マイナンバー
                </Col>
                <Col md="9">
                  <Row>
                    <Col md={targetData.myNumber?.status === "reviewing" ? 9 : 12}>
                      <Form.Control
                        type="text"
                        maxLength={12}
                        placeholder=""
                        value={
                          !currentValue && targetData.myNumber?.status === "reviewing" ? "************" : currentValue
                        }
                        onChange={(e) => {
                          $currentValue(e.target.value);
                        }}
                        onBlur={() =>
                          $entered({
                            ...entered,
                            currentValue: true,
                          })
                        }
                        isInvalid={entered.currentValue && !isNumberValid}
                        disabled={targetData.myNumber?.status === "reviewing"}
                      />
                      {entered.currentValue && !isNumberValid && (
                        <Form.Control.Feedback type="invalid">
                          マイナンバーは半角数字12桁で入力してください。
                        </Form.Control.Feedback>
                      )}
                    </Col>
                    {targetData.myNumber?.status === "reviewing" && (
                      <Col>
                        <Button
                          onClick={() => {
                            if (!targetData.myNumber) return;
                            dispatch(getMyNumber({ id: targetData.myNumber.id })).then((res: any) => {
                              $currentValue(res.payload);
                            });
                          }}
                          variant="outline-primary"
                          className="mx-1"
                        >
                          表示
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
              <Row className="--align-items-center mt-4">
                <Col md="3" className="--bold">
                  確認資料
                </Col>
                <Col md="9">
                  {files.length > 0 && (
                    <div className="mb-2">
                      {files.map((file, index) => (
                        <div key={`file${index}`} className={index > 0 ? "mt-4" : ""}>
                          <div className="--flex --align-items-center">
                            <div className="--font-s" key={index}>
                              {file.name}
                            </div>
                            <Button
                              disabled={targetData.myNumber?.status === "reviewing"}
                              onClick={() => deleteFile(index)}
                              variant="outline-danger"
                              size="sm"
                              className="mx-2"
                            >
                              削除
                            </Button>
                          </div>
                          <figure className="mt-1 --attached-image">
                            {file.type.includes("image") ? <img src={`${file.dataURIprefix},${file.dataURI}`} /> : null}
                          </figure>
                        </div>
                      ))}
                    </div>
                  )}
                  {(targetData.myNumber?.status === "unregistered" || targetData.myNumber?.status === "rejected") && (
                    <div>
                      <Uploader onFileLoad={onFileLoad} accepts={["image/*"]} />
                      {!isTouchDevice && <PhotoCapturer className="mt-2" onSave={onSave} />}
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  {(targetData.myNumber?.status === "unregistered" || targetData.myNumber?.status === "rejected") && (
                    <div className="Fixed-text-box mb-2">
                      {targetData.myNumber.dependent_id || targetData.myNumber.spouse_id
                        ? textForFamily
                        : textForEmployee}
                    </div>
                  )}
                  <div>
                    <Form.Check
                      type="checkbox"
                      id="polocyConfirmed"
                      key="polocyConfirmed"
                      label={"マイナンバーポリシーに同意する"}
                      disabled={targetData.myNumber?.status === "reviewing"}
                      checked={policyConfirmed}
                      value={`${policyConfirmed}`}
                      onChange={() => $policyConfirmed(!policyConfirmed)}
                    />
                  </div>
                </Col>
              </Row>
              {targetData.myNumber?.status === "reviewing" && (
                <div className="mt-4">
                  <div className="mb-2 text-sm">確認のため承認する対象者の名前を入力してください。</div>
                  <Row className="--align-items-center mt-2">
                    <Col md="3" className="--bold">
                      対象者名を入力
                    </Col>
                    <Col md="9">
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={repeatedName}
                        onChange={(e) => {
                          $repeatedName(e.target.value);
                        }}
                        onBlur={() =>
                          $entered({
                            ...entered,
                            repeatedName: true,
                          })
                        }
                        isInvalid={entered.repeatedName && repeatedName !== targetData.account.name}
                      />
                      {entered.repeatedName && repeatedName !== targetData.account.name && (
                        <Form.Control.Feedback type="invalid">名前が一致しません。</Form.Control.Feedback>
                      )}
                    </Col>
                  </Row>
                </div>
              )}
              <Row className="mt-4">
                <Col>
                  {targetData.myNumber?.status === "reviewing" && (
                    <Button onClick={() => $activeModal("before_commit")} disabled={!isValid} variant="primary">
                      承認
                    </Button>
                  )}
                  {targetData.myNumber?.status !== "reviewing" && (
                    <Button onClick={() => $activeModal("before_commit")} disabled={!isValid} variant="primary">
                      登録
                    </Button>
                  )}
                  <Button onClick={() => cancel()} variant="outline-secondary" className="mx-2">
                    戻る
                  </Button>
                </Col>
              </Row>
            </Container>
          )}
          <ModalDialog
            show={activeModal === "before_commit"}
            onCancel={() => {
              $activeModal("");
            }}
            onConfirm={commit}
            message={targetData?.myNumber?.status === "unregistered" ? "登録しますか？" : "承認しますか？"}
          />
          <ModalDialog
            show={activeModal === "error"}
            type="alert"
            onConfirm={() => {
              window.history.back();
            }}
            message={"処理中にエラーが発生しました。"}
          />
        </main>
      </div>
    </div>
  );
}

export default MyNumberDetail;
