import { FieldValidationRule, ProfileSubFieldType, ProfileCondition } from "../profile/profileFieldValues";

export type Sector = {
  sector_id: string;
  logical_name: string;
  category: string;
  is_view: boolean;
  is_summary: boolean;
  master_required: boolean;
  status: "todo" | "creating" | "setting_valid_to" | "deleting" | "done";
  displayStatus: string;
  description?: string;
  has_rename_targets: boolean;
  has_option_editable_columns: boolean;
};

export type Client = {
  id: string;
  name: string;
  plan: string;
  settingStatus: "preparing" | "done";
  adhocCells?: AdhocCell[];
};

export type Modifier = {
  id: string;
  key: string;
  label: string;
  fromFormat?: string;
  overwrite?: boolean;
  currentValueRequired?: boolean;
  valueRequired?: boolean;
  targetType?: "metadata" | "celldata";
  targetIdRegExp?: RegExp;
  targetKeyIdRegExp?: RegExp;
  importType?: string[];
};

const DATE_COLUMN_REG_EXP = /^(.+_at|.+_on|valid_to|valid_from|.+day)$/;
const CODE_COLUMN_REG_EXP = /^.+_code$/;

export const VALUE_MODIFIERS: Modifier[] = [
  { id: "_blank", key: "_blank", label: "--" },
  {
    id: "dedupe",
    key: "dedupe",
    label: "重複しないよう列を削減する",
    targetType: "metadata",
    importType: ["creating", "setting_valid_to", "deleting"],
  },
  {
    id: "combine",
    key: "combine",
    label: "同一キーのレコードを一つのレコードに結合する",
    targetType: "metadata",
    importType: ["creating"],
  },
  { id: "normalize", key: "normalize", label: "標準の文字種に統一する" },
  { id: "remove_space", key: "remove_space", label: "空白文字を削除する" },
  {
    id: "format_space_full",
    key: "format_space_full",
    label: "空白文字を全角へ変換する",
  },
  {
    id: "format_space_half",
    key: "format_space_half",
    label: "空白文字を半角へ変換する",
  },
  { id: "to_date", key: "to_date", label: "日付形式へ変換する", targetIdRegExp: DATE_COLUMN_REG_EXP },
  {
    id: "format_date_LE",
    key: "format_date",
    fromFormat: "LE",
    label: "DD/MM/YYYY 形式の文字列から日付へ変換する",
    targetIdRegExp: DATE_COLUMN_REG_EXP,
  },
  {
    id: "format_date_ME",
    key: "format_date",
    fromFormat: "ME",
    label: "MM/DD/YYYY 形式の文字列から日付へ変換する",
    targetIdRegExp: DATE_COLUMN_REG_EXP,
  },
  {
    id: "complement_code_overwrite",
    key: "complement_code",
    overwrite: true,
    label: "コード値を名称から補完する（値がある場合上書きする）",
    targetIdRegExp: CODE_COLUMN_REG_EXP,
  },
  {
    id: "complement_code",
    key: "complement_code",
    overwrite: false,
    label: "コード値を名称から補完する（値がある場合上書きしない）",
    targetIdRegExp: CODE_COLUMN_REG_EXP,
  },
  {
    id: "zero_padding",
    key: "zero_padding",
    label: "桁数を合わせる（桁数不足の場合先頭に0を埋める）",
    valueRequired: true,
    targetIdRegExp: CODE_COLUMN_REG_EXP,
  },
  {
    id: "bulk_set_values",
    key: "bulk_set_values",
    label: "一括で値を設定する",
    valueRequired: true,
    targetKeyIdRegExp: /^valid_from$/,
  },
  {
    id: "replace_value",
    key: "replace_value",
    label: "値を置換する",
    currentValueRequired: true,
    valueRequired: true,
    targetIdRegExp: /^.+_type$/,
  },
  {
    id: "remove_time",
    key: "remove_time",
    label: "時刻を削除する",
    targetIdRegExp: DATE_COLUMN_REG_EXP,
  },
];

export type AdhocColumn = {
  label: string;
  isKey: boolean;
  regularColumnId: string;
  presetId: string;
};
export type ColumnValidator = {
  message: string;
  validate: (columns: AdhocColumn[]) => boolean;
};

export type RegularColumn = {
  id: string;
  label: string;
  required: boolean;
  required_conditions?: ProfileCondition;
  isKey: boolean;
  order: number;
  reference_field: boolean;
  virtual_field: boolean;
  edit_only: boolean;
  type: "metadata" | "content";
  input_type: ProfileSubFieldType;
  rules: FieldValidationRule[];
  rename_target: boolean;
  option_editable?: boolean;
  options?: string[];
  option_labels?: string[];
  display_conditions?: ProfileCondition;
  category_type?: string;
};

export type SectorRegularColumns = {
  [sectorId: string]: RegularColumn[];
};

export type AdhocCell = {
  id: string;
  correcting: boolean;
  column: string;
  comments: string[];
  key: string;
  is_valid: boolean;
  priority: string;
  record_id: string;
  type: string;
  value: number | string | boolean;
  errors: string[];
};
export type AdhocRecord = AdhocCell[];

export const METADATA_COLUMN: RegularColumn = {
  id: "metadata",
  label: "レコードの妥当性",
  isKey: false,
  required: false,
  order: 0,
  reference_field: false,
  virtual_field: false,
  edit_only: false,
  type: "metadata",
  input_type: "string",
  rename_target: false,
  rules: [],
};

export type SectorStatus = {
  key: string;
  label: string;
  description: string;
  active: boolean;
};

export const IMPORT_STATUSES = ["todo", "creating", "setting_valid_to", "deleting", "done"];

export const IMPORT_TYPES = [
  {
    id: "creating",
    label: "履歴追加",
  },
  {
    id: "setting_valid_to",
    label: "終了日の設定",
  },
  {
    id: "deleting",
    label: "履歴削除",
  },
];

export type ThreadBoxItem = {
  webScreen: string;
};

export type RawPreparationFile = {
  id: string;
  type: "import" | "raw";
  tags: string[];
  file_id: string;
  remarks: string;
  created_at: number;
};

export type PreparationFile = RawPreparationFile & {
  name: string;
  object_name: string;
  key: string;
};

export type RenamedColumns = {
  [sectorId: string]: { [columnName: string]: string };
};

export type ExternalColumns = {
  [sectorId: string]: { [columnName: string]: string };
};

export type AddingProfileFile = {
  id?: string;
  zipFilenames: string[];
  files: string[];
};

export type ProfileOption = {
  label: string;
  hidden: boolean;
};

export type EditingProfileOption = ProfileOption & {
  editable: boolean;
};

export type ExternalOption = {
  value: string;
  label: string;
};

export type PayrollSetting = {
  id: string;
  payroll_system: string;
};
export interface OptionType {
  value: string;
  label: string;
}

export type ItemColumn = { column: string };

export type ReportItem = {
  id: string;
  report_type: string;
  items: { [categoryType: string]: ItemColumn[] };
};

export const CHANGEABLE_SECTOR_IDS = ["profile_u_secondment", "profile_u_secondment_acceptance", "profile_u_union"];

export const PAYROLL_SYSTEMS = { bugyo: "bugyo", pca: "pca", obic7: "obic7" };
