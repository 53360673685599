import { useEffect, useState, useMemo } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import Table from "../../component/Table";
import Icon from "../../component/Icon";
import { getMembers, selectProfileState } from "./profileSlice";
import { selectCurrentCompany } from "../login/userSlice";
import { Row, Col, Alert, Form, Accordion } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const dispatch = useAppDispatch();
  const { accounts, accountLoading, allAccountsLoaded } = useAppSelector(selectProfileState);
  const { code: currentCompanyCode } = useAppSelector(selectCurrentCompany);
  useEffect(() => {
    if (!accountLoading) {
      dispatch(getMembers());
    }
  }, [dispatch]);
  const [status, $status] = useState([
    { label: "有効", checked: false, value: true },
    { label: "無効", checked: false, value: false },
  ]);
  const filteredAccounts = useMemo(() => {
    return accounts.filter((a) => {
      if (status.every((o) => !o.checked)) return true;
      return status.filter((o) => o.checked).some((o) => o.value === a.isActive);
    });
  }, [accounts, status]);

  return (
    <Row>
      <Col>
        <Row className="mt-2 mb-4">
          <Col md="2">
            <div className="--bold pt-md-3">絞込条件</div>
          </Col>
          <Col md="10">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {status.filter((o) => o.checked).length > 0
                    ? `ステータス : ${status
                        .filter((o) => o.checked)
                        .map((s) => s.label)
                        .join(",")}`
                    : "なし"}
                </Accordion.Header>
                <Accordion.Body>
                  <Row className="--align-items-center mb-1">
                    <Col md={5}>ステータス：</Col>
                    <Col md={7}>
                      {status.map((o, i) => (
                        <Form.Check
                          type="checkbox"
                          key={`check-${i}`}
                          id={`check-${i}`}
                          label={o.label}
                          checked={o.checked}
                          onChange={() => {
                            $status(
                              status.map((_o) => {
                                return {
                                  ..._o,
                                  checked: _o.label === o.label ? !o.checked : _o.checked,
                                };
                              })
                            );
                          }}
                          inline
                        />
                      ))}
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
        {accountLoading && filteredAccounts.length === 0 && (
          <div className="--flex --align-items-center --text-light-color">
            <div>
              <Icon type="box-arrow-down" width={24} height={24} />
            </div>
            <div className="--px-2">
              データをダウンロード中です。データ総数によっては 10
              秒以上かかる場合がありますが、この間他の画面に移動しても問題ありません。
            </div>
          </div>
        )}
        {allAccountsLoaded && filteredAccounts.length > 0 ? (
          <Table
            col={[
              {
                name: "氏名",
                colName: "name",
                filterable: true,
              },
              {
                name: "メールアドレス",
                filterable: true,
              },
              {
                name: "ステータス",
                filterable: true,
                width: 100,
              },
              {
                name: "メイン企業コード",
                filterable: true,
              },
            ]}
            row={filteredAccounts.map((a) => {
              return {
                key: a.id,
                data: [a.name, a.mailAddress, a.isActive ? "有効" : "無効", a.mainCompanyCode],
                link: `/_/account/detail/${a.id}`,
                appendAfter: {
                  name:
                    a.mainCompanyCode !== currentCompanyCode ? (
                      <div className="mx-1 badge rounded-pill bg-success">ゲスト</div>
                    ) : null,
                },
              };
            })}
            usePagenation={true}
            useKeywordFilter={true}
          />
        ) : (
          !accountLoading && <Alert variant={"info"}>該当するアカウント情報がありません。</Alert>
        )}
      </Col>
    </Row>
  );
}

export default App;
