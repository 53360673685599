import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Container, Row, Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getSections, selectSectionTree, SectionTreeNode } from "../section/sectionSlice";
import SnapshotTimeSelector, { onChangeOption } from "../../features/profile/SnapshotTimeSelector";
import dayjs from "dayjs";

function MasterDataList() {
  const dispatch = useAppDispatch();
  const tree = useAppSelector(selectSectionTree);
  const [openSectionIds, $openSectionIds] = useState([] as number[]);
  const [selectedPointDate, $selectedPointDate] = useState(dayjs());
  const [showSectionCode, $showSectionCode] = useState(false);
  useEffect(() => {
    dispatch(getSections({ baseDate: selectedPointDate }));
  }, [selectedPointDate]);

  const getNodeLayout = (node: SectionTreeNode, root: boolean = false) => {
    return (
      <div
        key={`node${node.id}`}
        className={classNames({
          Tree__node: true,
          "Tree__node--has-children": node.children.length > 0,
          "Tree__node--open": openSectionIds.includes(node.id),
          "--root": root,
        })}
      >
        <div
          onClick={() => {
            if (node.children.length === 0) return;
            openSectionIds.includes(node.id)
              ? $openSectionIds(openSectionIds.filter((_) => _ !== node.id))
              : $openSectionIds([...openSectionIds, node.id]);
          }}
          className={classNames({
            Tree__item: true,
            "Tree__item--has-children": node.children.length > 0,
            "Tree__item--open": openSectionIds.includes(node.id),
          })}
        >
          <div className="Tree__item-inner">
            {node.name}
            {showSectionCode ? ` (${node.sectionCode})` : ""}
          </div>
        </div>
        {openSectionIds.includes(node.id) && (
          <div className="Tree__children">{node.children.map((n) => getNodeLayout(n))}</div>
        )}
      </div>
    );
  };
  return (
    <Container>
      <Row className="mt-4 --align-items-center">
        <Col md="2">基準日</Col>
        <Col md="10">
          <SnapshotTimeSelector
            selectedPointDate={selectedPointDate}
            useFarthestDaySelector={false}
            useFarthestPastDaySelector={false}
            onChange={({ selectedPointDate }: onChangeOption) => {
              if (!selectedPointDate) return;
              $selectedPointDate(selectedPointDate);
            }}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Form.Check
            type="checkbox"
            label="部署コードを表示する"
            id="show_section_code"
            checked={showSectionCode}
            value="showSectionCode"
            onChange={(e) => {
              $showSectionCode(!showSectionCode);
            }}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Row className="mb-3">
            <Col>
              <div className="Tree">
                <div className="Tree__nodes">
                  {tree.map((node) => {
                    return getNodeLayout(node, true);
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default MasterDataList;
