import axios from "axios";
import { store } from "../app/store";
import { handleFatalError, handleError } from "../features/error/fatalErrorSlice";
import { reloadToken } from "../features/login/idTokenSlice";

type RequestMethod = "get" | "post" | "put" | "delete";

export const API_ORIGIN_ASSESSMENT = process.env.REACT_APP_API_ORIGIN_ASSESSMENT?.trim() as string;
export const API_ORIGIN_PROFILE = process.env.REACT_APP_API_ORIGIN_PROFILE?.trim() as string;

const DEFAULT_TIMEOUT = 60000;

const toCollaboratorFormat = (method: RequestMethod, apiAndResource: string, data: any) => {
  const [api, ...segments] = apiAndResource.split("/"),
    resource = segments.join("/");
  if (!Array.isArray(data)) {
    return {
      requests: [
        {
          method,
          resource,
          api,
          data,
        },
      ],
    };
  } else {
    return {
      requests: data.map((d: any) => ({
        method,
        resource,
        api,
        data: d,
      })),
    };
  }
};

/*
    usage:
    async ()=>{
        const ajaxResult = await get("get","project_manager/project")({ id: 3 })
    }
*/
export const call = (
  method: RequestMethod,
  apiAndResource: string,
  options: {
    origin?: string;
    version?: string;
    doReload?: boolean;
    handleError?: boolean;
  } = {}
): ((data?: any, format?: string) => Promise<any>) => {
  return (data: any = {}) => {
    const { token } = store.getState();
    if (!token) {
      return new Promise((_, reject) => {
        reject("Authorization required");
      });
    }
    const { origin = API_ORIGIN_PROFILE, version = "v1", doReload = true, handleError: _handleError = true } = options;
    const url = `${origin}/${version}/${apiAndResource}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token.idToken}`,
        "Content-Type": "application/json",
      },
      timeout: DEFAULT_TIMEOUT,
    };
    if (!["get", "post", "put", "delete"].includes(method)) {
      return new Promise((_, reject) => {
        reject("Unsupported request method");
      });
    }
    return new Promise(async (resolve, reject) => {
      try {
        let res;
        if (method === "post") {
          res = await axios.post(url, data, config);
        } else if (method === "get") {
          res = await axios({
            ...config,
            method: "get",
            url,
            params: data,
          });
        } else if (method === "put") {
          res = await axios.put(url, data, config);
        } else if (method === "delete") {
          res = await axios.delete(url, {
            ...config,
            data,
          });
        }
        if (doReload) {
          await store.dispatch(reloadToken());
        }
        resolve(res);
      } catch (e: any) {
        if (!_handleError) {
          reject(e);
          return;
        }
        const hint = e?.response?.data?.errors?.[0].hint ?? e?.message;
        const status = e?.response?.status;
        const reason = e?.response?.data?.errors?.[0].reason;
        if (status === 400) {
          store.dispatch(handleError({ type: "error", message: `リクエストが不正です。[${hint}]` }));
        } else if (status === 403 && reason === "W438 Forbidden") {
          store.dispatch(handleError({ type: "error", message: `権限がありません。[${hint}]` }));
        } else if (status === 500) {
          store.dispatch(handleError({ type: "error", message: `予期せぬエラーが発生しました。[${hint}]` }));
        } else if (hint === "Signature has expired") {
          store.dispatch(
            handleFatalError({
              type: "error",
              message: `ログイン情報の有効期限が切れています。再ログインしてください。[${hint}]`,
            })
          );
        }
        reject(e);
      }
    });
  };
};

export const callCollaborator = (
  method: RequestMethod,
  apiAndResource: string,
  version: string = "v1",
  options: {
    doReload?: boolean;
  } = {}
): ((data?: any, format?: string) => Promise<any>) => {
  return (data: any = {}) => {
    const { token } = store.getState();
    if (!token) {
      return new Promise((_, reject) => {
        reject("Authorization required");
      });
    }
    const { doReload = true } = options;
    const url = `${API_ORIGIN_ASSESSMENT}/${version}/collaborator/parallelize`;
    const config = {
      headers: {
        Authorization: `Bearer ${token.idToken}`,
        "Content-Type": "application/json",
      },
      timeout: DEFAULT_TIMEOUT,
    };
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.post(url, toCollaboratorFormat(method, apiAndResource, data), config);
        if (doReload) {
          await store.dispatch(reloadToken());
        }
        resolve(res);
      } catch (e: any) {
        const hint = e?.response?.data?.errors?.[0].hint ?? e?.message;
        let message: string;
        if (hint === "Signature has expired") {
          message = `ログイン情報の有効期限が切れています。再ログインしてください。[${hint}]`;
        } else {
          message = `例外的なエラーが発生しました。再ログインしてください。[${hint}]`;
        }
        store.dispatch(handleFatalError({ type: "error", message }));
        reject(e);
      }
    });
  };
};
