import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { ProfileState } from "../profile/profileSlice";
import { Account } from "../profile/profileValues";
import { call, callCollaborator } from "../../app/api";
import dayjs from "dayjs";

const SLICE_NAME = "myNumber";

export const myNumberStatusDictionary = {
  unregistered: "未登録",
  reviewing: "承認待ち",
  rejected: "差し戻し中",
  done: "登録済",
  deletable: "削除予定",
} as {
  [status: string]: string;
};

export const myNumberLogActionDictionary = {
  register: "申請",
  confirmed: "承認",
  reject: "差し戻し",
  browse: "閲覧",
  set_deletable: "削除予定に設定",
} as {
  [status: string]: string;
};

export const deletionPeriods = [
  { key: "1y", title: "1年" },
  { key: "2y", title: "2年" },
  { key: "3y", title: "3年" },
  { key: "4y", title: "4年" },
  { key: "5y", title: "5年" },
  { key: "6y", title: "6年" },
  { key: "7y", title: "7年" },
];

export type MyNumber = {
  id: number;
  account_id: number;
  company_id: number;
  spouse_id: number | null;
  dependent_id: number | null;
  value: string | null;
  status: string;
  status_reason: string;
  applied_at: number | null;
  confirmed_at: number | null;
  rejected_at: number | null;
  expired_at: number | null;
  files: string[];
};

export type MyNumberLog = {
  id: number;
  my_number_id: number;
  account_id: number;
  action: "register" | "confirmed" | "reject" | "browse" | "set_deletable";
  time: number;
};

export type MyNumberViewWithAccount = { myNumber: MyNumber | null; account: { name: string; retired: boolean } };

export type MyNumberDownloadLog = {
  id: number;
  issued_at: number;
  downloaded_at: number | null;
  account_id: number;
  purpose: string;
};

export type MyNumberDownloadLogWithAccount = {
  id: number;
  issuedTimeLabel: string;
  downloadedTimeLabel: string;
  purpose: string;
  account: Account | null;
};

interface MyNumberState {
  processing: boolean;
  myNumberViews: MyNumber[];
  concernedAccountIds: number[];
  logs: MyNumberLog[];
  textForEmployee: string;
  textForFamily: string;
  doDeleteFiles: boolean;
  notificationTemplate: string;
  deletionPeriod: string;
  spouses: any[];
  dependents: any[];
  resigneeAccountIds: number[];
  downloadLogs: MyNumberDownloadLog[];
}

const initialState: MyNumberState = {
  processing: false,
  myNumberViews: [],
  concernedAccountIds: [],
  logs: [],
  textForEmployee: "",
  textForFamily: "",
  doDeleteFiles: false,
  notificationTemplate: "",
  deletionPeriod: "",
  spouses: [],
  dependents: [],
  resigneeAccountIds: [],
  downloadLogs: [],
};

export const getMyNumberViews = createAsyncThunk<
  { myNumberViews: MyNumber[]; concernedAccountIds: number[] },
  { accountId?: number; dependentId?: number; spouseId?: number } | void
>(SLICE_NAME + "/getMyNumberViews", async (options) => {
  // - サービス「マイナンバー」のユーザー権限ロールを取得
  // - 対応するマイナンバーレコードを取得
  // - 未登録のマイナンバーレコードを補完

  const res = await call(
    "get",
    "account_admin_manager/role"
  )({
    service_id: 10,
    role_name: "user",
  });
  const concernedAccountIds = res.data.result.map((r: any) => r.account_id) as number[];
  const myNumberViews = [
    {
      id: 1,
      account_id: 1,
      company_id: 9999,
      spouse_id: null,
      dependent_id: null,
      value: null,
      status: "done",
      status_reason: "",
      files: [],
      applied_at: dayjs("2018-08-31").valueOf(),
      confirmed_at: dayjs("2018-09-02").valueOf(),
      rejected_at: null,
      expired_at: null,
    },
    {
      id: 11,
      account_id: 1,
      company_id: 9999,
      spouse_id: 3,
      dependent_id: null,
      value: null,
      status: "done",
      status_reason: "",
      files: [],
      applied_at: dayjs("2019-10-10").valueOf(),
      confirmed_at: dayjs("2019-10-14").valueOf(),
      rejected_at: null,
      expired_at: null,
    },
    {
      id: 2,
      account_id: 1,
      company_id: 9999,
      spouse_id: null,
      dependent_id: 1,
      value: null,
      status: "deletable",
      status_reason: "",
      files: [],
      applied_at: dayjs("2018-08-21").valueOf(),
      confirmed_at: dayjs("2018-08-22").valueOf(),
      rejected_at: null,
      expired_at: dayjs("2021-12-01").valueOf(),
    },
    {
      id: 3,
      account_id: 1,
      company_id: 9999,
      spouse_id: null,
      dependent_id: 2,
      value: null,
      status: "reviewing",
      status_reason: "",
      files: [],
      applied_at: dayjs("2024-03-05").valueOf(),
      confirmed_at: null,
      rejected_at: null,
      expired_at: null,
    },
    {
      id: 40,
      account_id: 9998,
      company_id: 9999,
      spouse_id: null,
      dependent_id: null,
      value: null,
      status: "rejected",
      status_reason: "書類不鮮明のため再提出お願いします。",
      files: [],
      applied_at: dayjs("2023-11-08").valueOf(),
      confirmed_at: null,
      rejected_at: dayjs("2023-11-09").valueOf(),
      expired_at: null,
    },
    {
      id: 4,
      account_id: 9998,
      company_id: 9999,
      spouse_id: 2,
      dependent_id: null,
      value: null,
      status: "done",
      status_reason: "",
      files: [],
      applied_at: dayjs("2020-11-12").valueOf(),
      confirmed_at: null,
      rejected_at: dayjs("2020-11-14").valueOf(),
      expired_at: null,
    },
    {
      id: 50,
      account_id: 3,
      company_id: 9999,
      spouse_id: null,
      dependent_id: null,
      value: null,
      status: "reviewing",
      status_reason: "",
      files: [],
      applied_at: dayjs("2024-02-09").valueOf(),
      confirmed_at: null,
      rejected_at: null,
      expired_at: null,
    },
  ] as MyNumber[];
  return { myNumberViews, concernedAccountIds };
});

export const getMyNumber = createAsyncThunk(SLICE_NAME + "/getMyNumber", async ({ id }: { id: number }) => {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return Math.floor(Math.random() * Math.pow(10, 12)) + "";
});

export const getMyNumberSettings = createAsyncThunk(SLICE_NAME + "/getMyNumberSettings", async () => {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return {
    deletionPeriod: "6m",
    doDeleteFiles: true,
    textForEmployee: `■ 利用目的の範囲

以下に示す利用目的の範囲内において、ご提供いただきました特定個人情報を利用します。

 
○ 税務

源泉徴収票作成事務

扶養控除等（異動）申告書、保険料控除申告書兼給与所得者の配偶者特別控除申請書作成業務

退職所得に関する申告書作成事務

財産形成住宅貯蓄・財産形成年金貯蓄に関する申告書、届出書及び申込書作成事務

 
○社会保険

健康保険・厚生年金保険届出事務

健康保険・厚生年金保険申請・請求事務

雇用保険・労災保険届出事務

雇用保険・労災保険申請・請求事務

雇用保険・労災保険証明書作成事務`,
    textForFamily: `■ 利用目的の範囲

以下に示す利用目的の範囲内において、ご提供いただきました特定個人情報を利用します。

（配偶者・家族向け）`,
    notificationTemplate: `【マイナンバー登録のお願い】

よろしくお願いします。
`,
  };
});

export const getMyNumberDownloadLogs = createAsyncThunk(SLICE_NAME + "/getMyNumberDownloadLogs", async () => {
  await new Promise<MyNumberDownloadLog[]>((resolve) => setTimeout(resolve, 1000));
  const data = [
    {
      id: 1,
      issued_at: dayjs("2023-11-10 15:00:00").unix(),
      downloaded_at: dayjs("2023-11-10 15:05:30").unix(),
      account_id: 9998,
      purpose: "書類を作成するため",
    },
    {
      id: 2,
      issued_at: dayjs("2023-11-28 11:20:00").unix(),
      downloaded_at: null,
      account_id: 9998,
      purpose: "問い合わせ対応のため",
    },
  ];
  return data.sort((a, b) => b.issued_at - a.issued_at);
});

export const getMyNumberLogs = createAsyncThunk<MyNumberLog[], { myNumberId: number }>(
  SLICE_NAME + "/getMyNumberLogs",
  async ({ myNumberId }) => {
    const dummy = [
      {
        id: 10,
        my_number_id: 1,
        action: "register",
        account_id: 1,
        time: dayjs("2018-08-31").valueOf(),
      },
      {
        id: 11,
        my_number_id: 1,
        action: "confirmed",
        account_id: 9998,
        time: dayjs("2018-09-02").valueOf(),
      },
      {
        id: 12,
        my_number_id: 1,
        action: "browse",
        account_id: 9998,
        time: dayjs("2019-12-22").valueOf(),
      },
      {
        id: 20,
        my_number_id: 2,
        action: "register",
        account_id: 1,
        time: dayjs("2018-08-21").valueOf(),
      },
      {
        id: 21,
        my_number_id: 2,
        action: "confirmed",
        account_id: 9998,
        time: dayjs("2018-08-22").valueOf(),
      },
      {
        id: 22,
        my_number_id: 2,
        action: "browse",
        account_id: 9998,
        time: dayjs("2021-11-09").valueOf(),
      },
      {
        id: 23,
        my_number_id: 2,
        action: "set_deletable",
        account_id: 9998,
        time: dayjs("2021-12-01").valueOf(),
      },
      {
        id: 30,
        my_number_id: 3,
        action: "register",
        account_id: 1,
        time: dayjs("2024-03-05").valueOf(),
      },
      {
        id: 40,
        my_number_id: 4,
        action: "register",
        account_id: 9998,
        time: dayjs("2020-11-12").valueOf(),
      },
      {
        id: 41,
        my_number_id: 4,
        action: "reject",
        account_id: 4,
        time: dayjs("2020-11-14").valueOf(),
      },
      {
        id: 110,
        my_number_id: 11,
        action: "register",
        account_id: 1,
        time: dayjs("2019-10-10").valueOf(),
      },
      {
        id: 111,
        my_number_id: 11,
        action: "confirmed",
        account_id: 1,
        time: dayjs("2019-10-14").valueOf(),
      },
      {
        id: 121,
        my_number_id: 50,
        action: "register",
        account_id: 3,
        time: dayjs("2024-02-09").valueOf(),
      },
      {
        id: 130,
        my_number_id: 40,
        action: "register",
        account_id: 9998,
        time: dayjs("2023-11-08").valueOf(),
      },
      {
        id: 131,
        my_number_id: 40,
        action: "reject",
        account_id: 3,
        time: dayjs("2023-11-09").valueOf(),
      },
    ] as MyNumberLog[];
    return dummy.filter((l) => l.my_number_id === myNumberId).sort((a, b) => b.time - a.time);
  }
);

export const getFamilyData = createAsyncThunk(
  SLICE_NAME + "/getFamilyData",
  async (options?: { account_id: number }) => {
    const [spouses, dependents] = await Promise.all([
      (async () => {
        const res = await call(
          "get",
          `user_data_manager/spouse`
        )(
          options?.account_id
            ? {
                account_id: options.account_id,
                base_date: dayjs().format("YYYY-MM-DD"),
              }
            : {
                base_date: dayjs().format("YYYY-MM-DD"),
              }
        );
        return res.data.result;
      })(),
      (async () => {
        const res = await call(
          "get",
          `user_data_manager/dependent`
        )(
          options?.account_id
            ? {
                account_id: options.account_id,
                base_date: dayjs().format("YYYY-MM-DD"),
              }
            : {
                base_date: dayjs().format("YYYY-MM-DD"),
              }
        );
        return res.data.result;
      })(),
    ]);
    return { spouses, dependents };
  }
);

export const getResignee = createAsyncThunk(SLICE_NAME + "/getResignee", async () => {
  const res = await call(
    "get",
    "user_data_manager/personal"
  )({
    enrollment_type__in: ["退職"],
  });
  const ids = res.data.result.map((r: any) => r.account_id);
  return ids;
});

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMyNumberViews.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getMyNumberViews.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getMyNumberViews.fulfilled, (state, action) => {
      state.processing = false;
      state.myNumberViews = action.payload.myNumberViews;
      state.concernedAccountIds = action.payload.concernedAccountIds;
    });
    builder.addCase(getMyNumber.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getMyNumber.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getMyNumber.fulfilled, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getMyNumberLogs.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getMyNumberLogs.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getMyNumberLogs.fulfilled, (state, action) => {
      state.processing = false;
      state.logs = action.payload;
    });
    builder.addCase(getMyNumberSettings.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getMyNumberSettings.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getMyNumberSettings.fulfilled, (state, action) => {
      state.processing = false;
      state.textForEmployee = action.payload.textForEmployee;
      state.textForFamily = action.payload.textForFamily;
      state.deletionPeriod = action.payload.deletionPeriod;
      state.doDeleteFiles = action.payload.doDeleteFiles;
      state.notificationTemplate = action.payload.notificationTemplate;
    });
    builder.addCase(getFamilyData.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getFamilyData.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getFamilyData.fulfilled, (state, action) => {
      state.processing = false;
      state.spouses = action.payload.spouses;
      state.dependents = action.payload.dependents;
    });
    builder.addCase(getResignee.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getResignee.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getResignee.fulfilled, (state, action) => {
      state.processing = false;
      state.resigneeAccountIds = action.payload;
    });
    builder.addCase(getMyNumberDownloadLogs.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getMyNumberDownloadLogs.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getMyNumberDownloadLogs.fulfilled, (state, action) => {
      state.processing = false;
      state.downloadLogs = action.payload;
    });
  },
});

export const selectMyNumberState = (state: RootState) => {
  return state.myNumber as MyNumberState;
};

export const selectMyNumberViewPerAccount = (
  state: RootState
): {
  accountId: number;
  self: MyNumberViewWithAccount;
  spouse: MyNumberViewWithAccount;
  dependent: MyNumberViewWithAccount[];
}[] => {
  // const { user } = state.user as UserState;
  const { accounts, selfAccount } = state.profile as ProfileState;
  const {
    myNumberViews: _views,
    spouses,
    dependents,
    concernedAccountIds,
    resigneeAccountIds,
  } = state.myNumber as MyNumberState;
  const nextViews = [..._views];
  const unregistered = {
    company_id: 0,
    spouse_id: null,
    dependent_id: null,
    value: null,
    status: "unregistered",
    status_reason: "",
    files: [],
    applied_at: null,
    confirmed_at: null,
    rejected_at: null,
    expired_at: null,
  };
  // 未登録のマイナンバーレコードを補完する
  let counter = 0;
  const _accounts = selfAccount ? [...accounts.filter((a) => a.id !== selfAccount.id), selfAccount] : accounts;
  concernedAccountIds.forEach((a, _i) => {
    // if (a.mainCompanyCode !== user.current_company.code) return;
    if (!nextViews.some((v) => v.account_id === a && !v.spouse_id && !v.dependent_id)) {
      // account に対応するマイナンバーレコードがない
      nextViews.push({
        ...unregistered,
        account_id: a,
        id: -1 * ++counter,
      });
    }
  });
  spouses.forEach((s, _i) => {
    // spouse に対応するマイナンバーレコードがない
    if (!nextViews.some((v) => v.account_id === s.account_id && v.spouse_id === s.id)) {
      nextViews.push({
        ...unregistered,
        spouse_id: s.id,
        account_id: s.account_id,
        id: -1 * ++counter,
      });
    }
  });
  dependents.forEach((s, _i) => {
    // dependent に対応するマイナンバーレコードがない
    if (!nextViews.some((v) => v.account_id === s.account_id && v.dependent_id === s.id)) {
      nextViews.push({
        ...unregistered,
        dependent_id: s.id,
        account_id: s.account_id,
        id: -1 * ++counter,
      });
    }
  });

  const views = nextViews.reduce((prev, current) => {
    let assorted = prev.find((a) => a.accountId === current.account_id);
    if (!assorted) {
      assorted = {
        accountId: current.account_id,
        self: { myNumber: null, account: { name: "", retired: resigneeAccountIds.includes(current.account_id) } },
        spouse: { myNumber: null, account: { name: "", retired: resigneeAccountIds.includes(current.account_id) } },
        dependent: [],
      };
    }
    if (!current.dependent_id && !current.spouse_id) {
      assorted.self.myNumber = current;
    } else if (current.spouse_id) {
      assorted.spouse.myNumber = current;
    } else {
      assorted.dependent.push({
        myNumber: current,
        account: { name: "", retired: resigneeAccountIds.includes(current.account_id) },
      });
    }
    // Github Actions で 'assorted' is possibly 'undefined' となったので、 assorted => assorted! で型を保証
    return [...prev.filter((a) => a.accountId !== assorted!.accountId), assorted!];
  }, [] as { accountId: number; self: MyNumberViewWithAccount; spouse: MyNumberViewWithAccount; dependent: MyNumberViewWithAccount[] }[]);

  views.forEach((view) => {
    if (view.self.myNumber?.account_id) {
      view.self.account.name = _accounts.find((a) => a.id === view.self.myNumber?.account_id)?.name ?? "";
    }
    if (view.spouse.myNumber?.account_id) {
      view.spouse.account.name =
        state.myNumber.spouses.find((a: any) => a.id === view.spouse.myNumber?.spouse_id)?.spouse_name ?? "";
    }
    view.dependent.forEach((d) => {
      if (d.myNumber?.account_id) {
        d.account.name =
          state.myNumber.dependents.find((a: any) => a.id === d.myNumber?.dependent_id)?.dependent_name ?? "";
      }
    });
  });
  return views;
};

export const selectLogsWithAccount = (state: RootState) => {
  const { accounts } = state.profile as ProfileState;
  const { logs } = state.myNumber as MyNumberState;
  return logs.map((log) => {
    return {
      log,
      account: accounts.find((a) => a.id === log.account_id),
    };
  });
};

export const selectDownloadLogsWithAccount = (state: RootState) => {
  const { accounts } = state.profile as ProfileState;
  const { downloadLogs } = state.myNumber as MyNumberState;
  return downloadLogs.map((log) => {
    return {
      id: log.id,
      issuedTimeLabel: dayjs.unix(log.issued_at).format("YYYY/MM/DD HH:mm:ss"),
      downloadedTimeLabel: log.downloaded_at ? dayjs.unix(log.downloaded_at).format("YYYY/MM/DD HH:mm:ss") : "",
      purpose: log.purpose,
      account: accounts.find((a) => a.id === log.account_id) ?? null,
    };
  }) as MyNumberDownloadLogWithAccount[];
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
