import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { callCollaborator } from "../../app/api";

const SLICE_NAME = "serializer";

interface SerializerState {
  processing: boolean;
}

const initialState: SerializerState = {
  processing: false,
};

export const serialize = createAsyncThunk(
  SLICE_NAME + "/serialize",
  async ({ data, filetype }: { data: string; filetype: string }) => {
    const res = await callCollaborator(
      "post",
      `seriarizer/loader`
    )({
      data,
      filetype: filetype,
    });
    return res.data.results[0].result;
  }
);

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(serialize.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(serialize.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(serialize.fulfilled, (state, action) => {
      state.processing = false;
    });
  },
});

export const selectSerializerState = (state: RootState) => {
  return state.serializer as SerializerState;
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
