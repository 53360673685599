import { useState, useMemo, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { selectCurrentCompany } from "../login/userSlice";
import { Container, Row, Col, Button, ListGroup, Form, Alert } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { GuestAccount } from "./profileValues";
import { AssessRole, serviceLabels } from "../permission/permissionValues";
import {
  getServiceContracts,
  postAssessRoles,
  deleteAssessRoles,
  getAssessRoles,
  selectPermissionState,
} from "../permission/permissionSlice";
import ModalDialog from "../../component/ModalDialog";
import { searchAccountWithToken } from "../profile/profileSlice";
import { setLoading } from "../notification/notificationSlice";
import { postVirtualTheme } from "../theme/themeSlice";
import { testResponse } from "../../app/util";

function App() {
  const dispatch = useAppDispatch();
  const current_company = useAppSelector(selectCurrentCompany);
  const { serviceContracts, assessRoles } = useAppSelector(selectPermissionState);
  const [nextAssessRoles, $nextAssessRoles] = useState([] as AssessRole[]);
  const [activeModal, $activeModal] = useState("");
  const [token, $token] = useState("");
  const [guest, $guest] = useState<GuestAccount | null>(null);
  const [isCompanyMember, $isCompanyMember] = useState(false);
  const [errorMessage, $errorMessage] = useState("");
  const [notFoundErrorMessage, $notFoundErrorMessage] = useState("");
  const [updateErrorMessage, $updateErrorMessage] = useState("");
  const [thisCompanyAssessRoles, $thisCompanyAssessRoles] = useState([] as AssessRole[]);

  useEffect(() => {
    if (current_company.id > 0) {
      dispatch(getServiceContracts({ companyId: current_company.id })); // ゲストの場合 current_company.id ではない
    }
  }, [current_company, dispatch]);
  useEffect(() => {
    if (guest) {
      dispatch(getAssessRoles({ accountId: guest.id }));
    }
  }, [guest]);

  const [rolesToDelete, rolesToPost] = useMemo(() => {
    return [
      thisCompanyAssessRoles.filter((old) => {
        return !nextAssessRoles.some((n) => n.id === old.id);
      }),
      nextAssessRoles.filter((role) => {
        return role.id === -1;
      }),
    ];
  }, [thisCompanyAssessRoles, nextAssessRoles]);

  const commitable = useMemo(() => {
    return JSON.stringify(thisCompanyAssessRoles) !== JSON.stringify(nextAssessRoles) && nextAssessRoles.length > 0;
  }, [thisCompanyAssessRoles, nextAssessRoles]);

  const search = async (token: string) => {
    const res = testResponse(await dispatch(searchAccountWithToken(token)));
    if (res?.payload.account) {
      $guest(res.payload.account);
      $isCompanyMember(res.payload.isCompanyMember);
      $notFoundErrorMessage("");
    } else {
      $guest(null);
      $isCompanyMember(false);
      $notFoundErrorMessage("アカウントトークンに対応するユーザーが見つかりませんでした。");
    }
  };

  const save = async () => {
    $activeModal("before_commit");
  };

  const commit = async () => {
    if (!guest) return;
    dispatch(setLoading(true));
    try {
      if (rolesToPost.length > 0) {
        await dispatch(
          postAssessRoles({
            assessRoles: rolesToPost,
          })
        );
      }
      if (rolesToDelete.length > 0) {
        await dispatch(
          deleteAssessRoles({
            assessRoles: rolesToDelete,
          })
        );
      }
      if (!isCompanyMember) {
        await dispatch(postVirtualTheme({ accountId: guest.id }));
      }
      search(token);
    } catch (e) {
      $updateErrorMessage("処理中にエラーが発生しました。");
    } finally {
      dispatch(setLoading(false));
      $activeModal("");
    }

    return false;
  };

  useEffect(() => {
    const thisCompanyAssessRoles = assessRoles.filter((r) => r.companyId === current_company.id);
    $thisCompanyAssessRoles(thisCompanyAssessRoles);
    $nextAssessRoles(thisCompanyAssessRoles);
  }, [assessRoles]);
  const checked = (roleName: string, serviceName: string) => {
    const matchedServiceContract = serviceContracts.find((_) => _.serviceName === serviceName);
    const matchedRoles = nextAssessRoles.filter((r) => r.serviceId === matchedServiceContract?.serviceId);
    return (
      <Form.Check
        key={`checkbox_${roleName}_${serviceName}`}
        type="checkbox"
        checked={matchedRoles.some((r) => r.roleName === roleName)}
        disabled={
          roleName === "admin" &&
          (() => {
            // 現在の管理者がこのサービスの管理者権限を持っている場合のみ、編集させる
            const sc = serviceContracts.find((_) => _.serviceName === serviceName);
            if (!sc) return false;
            return !current_company.services.admin?.includes(sc.serviceId);
          })()
        }
        onChange={(e) => {
          if (!matchedServiceContract || !guest) return;
          if (e.target.checked) {
            const currentRole = thisCompanyAssessRoles.find(
              (a) => a.serviceId === matchedServiceContract.serviceId && a.roleName === roleName
            );
            /*
              型定義に沿わせるために loginCode, mainCompanyCode を空文字にしている。
              この画面では使わない値なので問題ないと考える。
            */
            $nextAssessRoles([
              ...nextAssessRoles,
              {
                accountId: guest.id,
                companyId: current_company.id,
                currentCompanyCode: current_company.code,
                currentCompanyName: current_company.company_name,
                id: currentRole ? currentRole.id : -1,
                loginCode: "",
                mainCompanyName: guest.companyName,
                mainCompanyCode: "",
                name: guest.name,
                roleName: roleName,
                serviceId: matchedServiceContract.serviceId,
              },
            ]);
          } else {
            $nextAssessRoles(
              nextAssessRoles.filter((r) => r.serviceId !== matchedServiceContract.serviceId || r.roleName !== roleName)
            );
          }
        }}
      />
    );
  };

  return (
    <div>
      <Container>
        <Row className="mb-2">
          <Col>
            <div className="my-2">他の企業に所属するゲストアカウントがこの企業で利用可能なサービスを編集できます。</div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Row className="--align-items-center">
              <Col md={3}>
                <div className="--bold">アカウントトークン ※</div>
              </Col>
              <Col md={9}>
                <Row>
                  <Col>
                    <Form.Control
                      type="text"
                      id="token"
                      value={token}
                      onChange={(e) => {
                        $token(e.target.value);
                        search(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="--font-s">
                ※アカウントの個人設定画面で取得できます。編集したいゲストアカウントのアカウントトークンが分からない場合、ゲストアカウントに依頼して共有してもらいましょう。
              </Col>
            </Row>
          </Col>
        </Row>
        {guest && (
          <div>
            <Row className="mt-4 --align-items-center">
              <Col md={3}>
                <div className="--bold">編集中のゲスト</div>
              </Col>
              <Col md={9}>
                <div className="--pre-wrap">
                  ({guest.companyName}){guest.name}
                </div>
              </Col>
            </Row>
            <Row className="mt-2 --align-items-center">
              <Col md={3}>
                <div className="--bold">現在の設定状況</div>
              </Col>
              <Col md={9}>
                <div className="--pre-wrap">
                  {guest.companyName !== current_company.company_name
                    ? isCompanyMember
                      ? "ゲストアカウントに設定している"
                      : "ゲストアカウントに設定していない"
                    : "現在の企業のメンバーである"}
                </div>
              </Col>
            </Row>
            <Row className="mb-2 mt-4">
              <Col>
                <span className="--required-label"></span> は必須項目です。
              </Col>
            </Row>
            <h2 className="Headline--section-sub mb-2 mt-2 --required-label">この企業で利用可能なサービス</h2>
            <ListGroup>
              <ListGroup.Item>
                <Row>
                  <Col md={3}>
                    <div className="--bold">人材開発</div>
                  </Col>
                  <Col md={9}>
                    {nextAssessRoles.length > 0
                      ? `利用可 (${nextAssessRoles
                          .reduce((prev, current) => {
                            if (!prev.includes(current.roleName)) {
                              return [...prev, current.roleName];
                            } else {
                              return prev;
                            }
                          }, [] as string[])
                          .map((roleName) => (roleName === "admin" ? "管理者" : roleName === "user" ? "ユーザ" : ""))
                          .filter((_) => _)
                          .join(",")})`
                      : "利用不可"}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item className="text-center">
                <Row className="my-2">
                  <Col md={6}></Col>
                  <Col md={3} className="--bold">
                    管理者
                  </Col>
                  <Col md={3} className="--bold">
                    ユーザ
                  </Col>
                </Row>
                {serviceContracts
                  .map((sc) => {
                    if (sc.serviceId === 9 || sc.serviceId === 3) return null;
                    return (
                      <Row className="my-2" key={sc.serviceOrder}>
                        <Col md={6}>{serviceLabels[sc.serviceName].label}</Col>
                        <Col md={3}>{checked("admin", sc.serviceName)}</Col>
                        <Col md={3}>{[1, 5].includes(sc.serviceId) ? "" : checked("user", sc.serviceName)}</Col>
                      </Row>
                    );
                  })
                  .filter((_) => _)}
                {errorMessage && (
                  <Row>
                    <Col>
                      <div className="--text-annotation mt-1 --font-s">{errorMessage}</div>
                    </Col>
                  </Row>
                )}
              </ListGroup.Item>
            </ListGroup>
            <ListGroup className="mt-4">
              <ListGroup.Item>
                <Row>
                  <Col md={3}>
                    <div className="--bold">人事労務</div>
                  </Col>
                  <Col md={9}>{nextAssessRoles.some((r) => r.serviceId === 9) ? "利用可" : "利用不可"}</Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
            <Row className="mt-4">
              <Col>
                <Button onClick={save} disabled={!commitable} className="mx-1">
                  {isCompanyMember ? "更新" : "登録"}
                </Button>
              </Col>
            </Row>
          </div>
        )}
        {notFoundErrorMessage && (
          <Row>
            <Col>
              <Alert variant="danger">{notFoundErrorMessage}</Alert>
            </Col>
          </Row>
        )}
        <ModalDialog
          show={activeModal === "updateError"}
          onConfirm={() => {
            $activeModal("");
          }}
          onCancel={() => {
            $activeModal("");
          }}
          message={updateErrorMessage}
          type="alert"
        />
        <ModalDialog
          show={activeModal === "before_commit"}
          onCancel={() => {
            $activeModal("");
          }}
          onConfirm={commit}
          message="更新しますか？"
        />
      </Container>
    </div>
  );
}

export default App;
