import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/store";
import {
  deleteRemindSetting,
  getRemindSetting,
  getRemindSettings,
  postRemindSetting,
  putRemindSetting,
  selectTodoState,
  unselectRemindSetting,
} from "./todoSlice";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Accordion } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import ModalDialog from "../../component/ModalDialog";
import profileTodoTypes from "../../profile_todo_types.json";
import { getAllTerms } from "../../app/translate";
import { DueDateTypes, IntervalTypes, PeriodTypes, RemindSetting, SubjectTypes } from "./todoValues";
import { getRoles, selectPermissionState } from "../permission/permissionSlice";
import { selectUserState } from "../login/userSlice";
import { getQuery } from "../../app/util";
import { getPositions, selectSectionState, Section } from "../section/sectionSlice";
import dayjs from "dayjs";
import SectionSelector from "../section/SectionSelector";
function App() {
  const TERMS = getAllTerms();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selectedRemindSetting, remindSettings } = useAppSelector(selectTodoState);
  const { roles } = useAppSelector(selectPermissionState);
  const { user } = useAppSelector(selectUserState);
  const { positions } = useAppSelector(selectSectionState);
  const { search } = useLocation();
  const [state, $state] = useState({
    title: "",
    initTitle: "",
    content: "",
    todo_type: "",
    period_type: "",
    period_value: 1 as number | null,
    interval_type: "",
    timing: 1 as number | null,
    subject_type: "",
    subject_values: [] as string[],
    due_date_base: "",
    due_date_period_value: 1 as number | null,
    due_date_period_type: "",
    is_active: false,
    selectedSections: [] as Section[],
  });
  const [activeModal, $activeModal] = useState("");

  useEffect(() => {
    return () => {
      dispatch(unselectRemindSetting());
    };
  }, []);
  const query = useMemo(() => {
    return getQuery(search);
  }, [search]);

  useEffect(() => {
    if (query.id) {
      dispatch(getRemindSetting({ id: query.id }));
    } else if (query.key && !remindSettings.length) {
      // 指定されたkeyをもつデータがあるか確認→なければデフォルト値を取得
      dispatch(getRemindSetting({ key: query.key })).then((res) => {
        if (!res.payload.id) {
          dispatch(getRemindSettings());
        }
      });
    }
  }, [query]);

  const [isValid, messages] = useMemo(() => {
    const messages = [] as { place: string; message: string }[];
    if (!state.title) {
      messages.push({ place: "title", message: "入力してください。" });
    }
    if (!state.todo_type) {
      messages.push({ place: "todotype", message: "選択してください。" });
    }
    if (!state.period_type) {
      messages.push({ place: "period", message: "選択してください。" });
    } else if (PeriodTypes[state.period_type].isValueRequired) {
      const valueRange = PeriodTypes[state.period_type].valueRange;
      if (state.period_value === null) {
        messages.push({ place: "period", message: "値を入力してください。" });
      } else if (valueRange && valueRange[0] > state.period_value) {
        messages.push({ place: "period", message: `${valueRange[0]}以上の数値を入力してください。` });
      } else if (valueRange && valueRange[1] < state.period_value) {
        messages.push({ place: "period", message: `${valueRange[1]}以下の数値を入力してください。` });
      }
    }
    const selectableIntervalTypes = PeriodTypes[state.period_type]?.selectableIntervalTypes ?? [];
    if (!state.interval_type) {
      messages.push({ place: "interval", message: "選択してください。" });
    } else if (state.period_type && !selectableIntervalTypes.includes(state.interval_type)) {
      messages.push({
        place: "interval",
        message: `「${TERMS[`PERIOD_TYPE_${state.period_type}`]}」を選択中のため「${selectableIntervalTypes
          .map((type) => TERMS[`INTERVAL_TYPE_${type}`])
          .join("」,「")}」のみ選択できます`,
      });
    } else if (IntervalTypes[state.interval_type].isValueRequired) {
      const valueRange = IntervalTypes[state.interval_type].valueRange;
      if (state.timing === null) {
        messages.push({ place: "interval", message: "値を入力してください。" });
      } else if (valueRange && valueRange[0] > state.timing) {
        messages.push({ place: "interval", message: `${valueRange[0]}以上の数値を入力してください。` });
      } else if (valueRange && valueRange[1] < state.timing) {
        messages.push({ place: "interval", message: `${valueRange[1]}以下の数値を入力してください。` });
      }
    }
    if (!state.subject_type || (state.subject_type !== "SELF" && state.subject_values.length === 0)) {
      messages.push({ place: "subject", message: "選択してください。" });
    }

    if (!state.due_date_period_type) {
      messages.push({ place: "due_date", message: "選択してください。" });
    } else if (PeriodTypes[state.due_date_period_type].isValueRequired) {
      const valueRange = PeriodTypes[state.due_date_period_type].valueRange;
      if (state.due_date_period_value === null) {
        messages.push({ place: "due_date", message: "値を入力してください。" });
      } else if (valueRange && valueRange[0] > state.due_date_period_value) {
        messages.push({ place: "due_date", message: `${valueRange[0]}以上の数値を入力してください。` });
      } else if (valueRange && valueRange[1] < state.due_date_period_value) {
        messages.push({ place: "due_date", message: `${valueRange[1]}以下の数値を入力してください。` });
      }
    }

    return [messages.length === 0, messages];
  }, [state]);

  const _setInitialValues = (remindSetting: RemindSetting) => {
    $state({
      ...state,
      initTitle: remindSetting.title,
      title: remindSetting.title,
      content: remindSetting.content ?? "",
      todo_type: remindSetting.todo_type,
      period_type: remindSetting.period_type,
      period_value: remindSetting.period_value ?? 0,
      interval_type: remindSetting.interval_type,
      timing: remindSetting.timing ?? 0,
      subject_type: remindSetting.subject_type,
      subject_values: remindSetting.subject_values ?? [],
      due_date_base: remindSetting.due_date_base,
      due_date_period_value: remindSetting.due_date_period_value,
      due_date_period_type: remindSetting.due_date_period_type,
      is_active: remindSetting.is_active,
    });
  };

  useEffect(() => {
    if (selectedRemindSetting.id) {
      // DBのデータから取得
      _setInitialValues(selectedRemindSetting);
    }
  }, [selectedRemindSetting]);

  useEffect(() => {
    if (query.key && remindSettings.length) {
      // キーから値取得
      const remindSetting = remindSettings.find((s) => s.key === query.key);
      if (!remindSetting) return;
      _setInitialValues(remindSetting);
    }
  }, [remindSettings]);

  const saveSetting = async () => {
    let remindSetting = {
      title: state.title,
      content: state.content,
      todo_type: state.todo_type,
      period_type: state.period_type,
      period_value: PeriodTypes[state.period_type].isValueRequired ? state.period_value : null,
      interval_type: state.interval_type,
      timing: IntervalTypes[state.interval_type].isValueRequired ? state.timing : null,
      subject_type: state.subject_type,
      subject_values: state.subject_values,
      due_date_base: state.due_date_base,
      due_date_period_value: PeriodTypes[state.due_date_period_type].isValueRequired
        ? state.due_date_period_value
        : null,
      due_date_period_type: state.due_date_period_type,
      is_active: state.is_active,
    } as RemindSetting;
    if (selectedRemindSetting.id) {
      remindSetting.id = selectedRemindSetting.id;
      await dispatch(putRemindSetting(remindSetting));
    } else {
      if (query.key) remindSetting.key = query.key;
      await dispatch(postRemindSetting(remindSetting));
    }

    navigate("/_/masters/remind_setting");
  };

  const deleteSetting = async () => {
    if (!selectedRemindSetting.id) return;
    await dispatch(deleteRemindSetting(selectedRemindSetting.id));
    navigate("/_/masters/remind_setting");
  };
  useEffect(() => {
    if (user.id) {
      dispatch(getPositions({ baseDate: dayjs() }));
      dispatch(getRoles());
    }
  }, [user]);
  return (
    <Container>
      <Row className="mt-4">
        <Col>
          {state.initTitle && <h2 className="Headline--section mb-2">{state.initTitle}</h2>}
          <div className="mb-3">
            <Form.Label className="--required-label --bold">タイトル</Form.Label>
            <Row>
              <Col md={8}>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={state.title}
                  onChange={(e) => $state({ ...state, title: e.target.value })}
                />
              </Col>
            </Row>
            {(() => {
              const m = messages.find((message) => message.place === "title");
              return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
            })()}
          </div>
          <div className="mb-3">
            <Form.Label className="--bold">内容</Form.Label>
            <Row>
              <Col md={12}>
                <Form.Control
                  as="textarea"
                  placeholder=""
                  value={state.content}
                  onChange={(e) => $state({ ...state, content: e.target.value })}
                />
              </Col>
            </Row>
          </div>
          <div className="mb-3">
            <Form.Label className="--required-label --bold">対象日</Form.Label>
            <Row>
              <Col md={4}>
                <Form.Select value={state.todo_type} onChange={(e) => $state({ ...state, todo_type: e.target.value })}>
                  <option value="">---</option>
                  {Object.keys(profileTodoTypes).map((value, i) => {
                    return (
                      <option key={`option${i}`} value={value}>
                        {TERMS[`TODO_TYPE_${value}`]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            {(() => {
              const m = messages.find((message) => message.place === "todotype");
              return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
            })()}
          </div>
          <div className="mb-3">
            <Form.Label className="--required-label --bold">条件</Form.Label>
            <Row>
              <Col md={2} className="d-flex align-items-center">
                対象日の
              </Col>
              {PeriodTypes[state.period_type]?.isValueRequired && (
                <Col md={3}>
                  <Form.Control
                    type="number"
                    placeholder=""
                    value={state.period_value === null || Number.isNaN(state.period_value) ? "" : state.period_value}
                    onChange={(e) => $state({ ...state, period_value: e.target.value === "" ? null : +e.target.value })}
                    onWheel={(e) => e.currentTarget.blur()}
                  />
                </Col>
              )}
              <Col md={5}>
                <Form.Select
                  value={state.period_type}
                  onChange={(e) => $state({ ...state, period_type: e.target.value })}
                >
                  <option value="">---</option>
                  {Object.keys(PeriodTypes).map((value, i) => {
                    return (
                      <option key={`option${i}`} value={value}>
                        {TERMS[`PERIOD_TYPE_${value}`]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            {(() => {
              const m = messages.find((message) => message.place === "period");
              return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
            })()}
          </div>
          <div className="mb-3">
            <Form.Label className="--required-label --bold">ToDo作成タイミング</Form.Label>
            <Row>
              <Col md={4}>
                <Form.Select
                  value={state.interval_type}
                  onChange={(e) => $state({ ...state, interval_type: e.target.value })}
                >
                  <option value="">---</option>
                  {Object.keys(IntervalTypes).map((value, i) => {
                    return (
                      <option key={`option${i}`} value={value}>
                        {TERMS[`INTERVAL_TYPE_${value}`]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
              {IntervalTypes[state.interval_type]?.isValueRequired && (
                <Col md={3}>
                  <Form.Control
                    type="number"
                    placeholder=""
                    value={state.timing === null || Number.isNaN(state.timing) ? "" : state.timing}
                    onChange={(e) => $state({ ...state, timing: e.target.value === "" ? null : +e.target.value })}
                    onWheel={(e) => e.currentTarget.blur()}
                  />
                </Col>
              )}
              {IntervalTypes[state.interval_type]?.unit && (
                <Col md={5} className="d-flex align-items-center">
                  {IntervalTypes[state.interval_type]?.unit}
                </Col>
              )}
            </Row>
            {(() => {
              const m = messages.find((message) => message.place === "interval");
              return m ? (
                <div className="--text-annotation mt-1 --font-s">{m.message}</div>
              ) : (
                <>
                  {state.interval_type === "MONTHLY" &&
                    state.timing &&
                    !messages.some((message) => message.place === "interval") && (
                      <span className="--font-s mt-1">
                        ※毎月{state.timing}日に、当月{state.timing}日~
                        {state.timing === 1 ? "末日" : `翌月${state.timing - 1}日`}
                        に条件に該当するデータについてToDoを作成します。
                      </span>
                    )}
                  {state.interval_type === "YEARLY" &&
                    state.timing &&
                    !messages.some((message) => message.place === "interval") && (
                      <span className="--font-s mt-1">
                        ※毎年{state.timing}月1日に、{state.timing}月1日~
                        {state.timing === 1 ? "年末" : `翌年${state.timing - 1}月末`}
                        に条件に該当するデータについてToDoを作成します。
                      </span>
                    )}
                </>
              );
            })()}
          </div>
          <div className="mb-3">
            <Form.Label className="--required-label --bold">ToDo実施者</Form.Label>
            <div className="--font-s">
              ※条件に該当するアカウントには、指定された「ToDo作成タイミング」でToDo作成と同時にメール通知されます。
            </div>
            <Row>
              <Col md={4}>
                <Form.Select
                  value={state.subject_type}
                  onChange={(e) => $state({ ...state, subject_type: e.target.value, subject_values: [] })}
                >
                  <option value="">---</option>
                  {SubjectTypes.map((value, i) => {
                    return (
                      <option key={`option${i}`} value={value}>
                        {TERMS[`SUBJECT_TYPE_${value}`]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col md={8}>
                {state.subject_type === "ROLE" && (
                  <Accordion>
                    <Accordion.Item eventKey={"role"}>
                      <Accordion.Header>{state.subject_values.length ?? 0} 件選択中</Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          {roles.map((role) => {
                            return (
                              <Col md={4} key={role.id}>
                                <Form.Check
                                  type="checkbox"
                                  id={role.id}
                                  label={role.label}
                                  checked={state.subject_values.includes(role.id)}
                                  value={role.id}
                                  className="my-1"
                                  onChange={() => {
                                    const isCheckedNow = state.subject_values.includes(role.id);
                                    const next = isCheckedNow
                                      ? state.subject_values.filter((v) => v !== role.id)
                                      : [...state.subject_values, role.id];
                                    $state({ ...state, subject_values: next });
                                  }}
                                ></Form.Check>
                              </Col>
                            );
                          })}
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )}
                {state.subject_type === "POSITION" && (
                  <Accordion>
                    <Accordion.Item eventKey={"position"}>
                      <Accordion.Header>
                        {state.subject_values.length ?? 0} 件選択中
                        <span className="--font-s ms-2">
                          ※対象者と同じ部署内で、選択された役職をもつアカウントが対象になります
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          {positions.map((position) => {
                            return (
                              <Col md={4} key={position.positionCode}>
                                <Form.Check
                                  type="checkbox"
                                  id={position.positionCode}
                                  label={position.positionName}
                                  checked={state.subject_values.includes(position.positionCode)}
                                  value={position.positionCode}
                                  className="my-1"
                                  onChange={() => {
                                    const isCheckedNow = state.subject_values.includes(position.positionCode);
                                    const next = isCheckedNow
                                      ? state.subject_values.filter((v) => v !== position.positionCode)
                                      : [...state.subject_values, position.positionCode];
                                    $state({ ...state, subject_values: next });
                                  }}
                                ></Form.Check>
                              </Col>
                            );
                          })}
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )}
                {state.subject_type === "SECTION" && (
                  <Accordion>
                    <Accordion.Item eventKey={"section"}>
                      <Accordion.Header>{state.subject_values.length ?? 0} 件選択中</Accordion.Header>
                      <Accordion.Body>
                        <SectionSelector
                          selectedSectionCodes={state.subject_values}
                          onChange={(selectedSectionCodes) => {
                            $state({ ...state, subject_values: selectedSectionCodes });
                          }}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )}
              </Col>
            </Row>
            {(() => {
              const m = messages.find((message) => message.place === "subject");
              return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
            })()}
          </div>
          <div className="mb-3">
            <Form.Label className="--required-label --bold">対応期限</Form.Label>
            <Row>
              <Col md={4}>
                <Form.Select
                  value={state.due_date_base}
                  onChange={(e) => $state({ ...state, due_date_base: e.target.value })}
                >
                  <option value="">---</option>
                  {DueDateTypes.map((value, i) => {
                    return (
                      <option key={`option${i}`} value={value}>
                        {TERMS[`DUE_DATE_BASE_${value}`]}
                        {value === "TARGET_DATE" && state.todo_type && `（${TERMS[`TODO_TYPE_${state.todo_type}`]}）`}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
              {PeriodTypes[state.due_date_period_type]?.isValueRequired && (
                <Col md={2}>
                  <Form.Control
                    type="number"
                    placeholder=""
                    value={
                      state.due_date_period_value === null || Number.isNaN(state.due_date_period_value)
                        ? ""
                        : state.due_date_period_value
                    }
                    onChange={(e) =>
                      $state({ ...state, due_date_period_value: e.target.value === "" ? null : +e.target.value })
                    }
                    onWheel={(e) => e.currentTarget.blur()}
                  />
                </Col>
              )}
              <Col md={4}>
                <Form.Select
                  value={state.due_date_period_type}
                  onChange={(e) => $state({ ...state, due_date_period_type: e.target.value })}
                >
                  <option value="">---</option>
                  {Object.keys(PeriodTypes)
                    .filter((type) => PeriodTypes[type].forDueDate)
                    .map((value, i) => {
                      return (
                        <option key={`option${i}`} value={value}>
                          {TERMS[`PERIOD_TYPE_${value}`]}
                        </option>
                      );
                    })}
                </Form.Select>
              </Col>
            </Row>
            {(() => {
              const m = messages.find((message) => message.place === "due_date");
              return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
            })()}
          </div>
          <div className="mb-3">
            <Form.Label className="--required-label --bold">有効/無効</Form.Label>
            <Row>
              <Col md={4}>
                <Form.Check
                  type="switch"
                  key="switch"
                  id="switch"
                  label={state.is_active ? "有効" : "無効"}
                  onChange={() => $state({ ...state, is_active: !state.is_active })}
                  checked={state.is_active}
                  className="text-secondary"
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Button
        variant="primary"
        onClick={() => {
          $activeModal("before_save");
        }}
        disabled={!isValid}
      >
        保存
      </Button>
      {selectedRemindSetting.id && !selectedRemindSetting.key && (
        <Button
          variant="danger"
          className="float-end"
          onClick={() => {
            $activeModal("before_delete");
          }}
        >
          削除
        </Button>
      )}

      <ModalDialog
        show={activeModal === "before_save"}
        onConfirm={() => {
          saveSetting();
        }}
        onCancel={() => {
          $activeModal("");
        }}
        message="保存します。よろしいですか？"
      />
      <ModalDialog
        show={activeModal === "before_delete"}
        onConfirm={() => {
          deleteSetting();
        }}
        onCancel={() => {
          $activeModal("");
        }}
        type="destructiveConfirm"
        confirmButtonName="削除"
        message="削除します。よろしいですか？"
      />
    </Container>
  );
}

export default App;
