import { useState, useEffect, useMemo } from "react";
import { Row, Col, Accordion, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ACCOUNT_SEARCH_LIMIT } from "./profileValues";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { clearFilteredAccounts, searchAccounts, getMyStaff, selectProfileState } from "./profileSlice";

function MemberSelector({
  selectedAccountId,
  onSelectedAccountChange,
}: {
  selectedAccountId?: number;
  onSelectedAccountChange: (id: number) => any;
}) {
  const dispatch = useAppDispatch();
  const [state, $state] = useState({
    keyword: "",
    checkedsectionCodes: [] as string[],
    timeoutId: null as number | null,
  });

  const { filteredAccounts } = useAppSelector(selectProfileState);

  const selectTarget = (id: number) => {
    onSelectedAccountChange(id);
  };

  /*
  const selectSection = (id: string) => {
    let next = [...state.checkedsectionCodes];
    if (next.includes(id)) {
      next = next.filter((_) => _ !== id);
    } else {
      next = [...next, id];
    }
    $state({ ...state, checkedsectionCodes: next });
  };

  */
  const onChangeKeyWord = (keyword: string) => {
    // 打ち終わって500ms後に検索のリクエストをする
    if (state.timeoutId) {
      window.clearTimeout(state.timeoutId);
    }
    const timeoutId = window.setTimeout(() => {
      if (keyword) {
        dispatch(searchAccounts({ keyword }));
      } else {
        dispatch(getMyStaff({}));
      }
      $state({ ...state, timeoutId: null, keyword });
    }, 500);
    $state({ ...state, timeoutId, keyword });
  };

  const checkedMember = useMemo(() => {
    return filteredAccounts.find((t) => selectedAccountId === t.id);
  }, [filteredAccounts, selectedAccountId]);

  useEffect(() => {
    if (selectedAccountId && filteredAccounts.length > 0) {
      if (filteredAccounts.some(({ id }) => id === selectedAccountId)) {
        // アカウントが候補にあれば、選択状態とする
        onSelectedAccountChange(selectedAccountId);
      } else if (!state.keyword) {
        // キーワード入力中ではなく候補にない場合、取得しに行く
        dispatch(searchAccounts({ accountId: selectedAccountId }));
      }
    }
  }, [selectedAccountId, filteredAccounts]);

  useEffect(() => {
    dispatch(getMyStaff({}));
    return () => {
      dispatch(clearFilteredAccounts());
    };
  }, []);

  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>{checkedMember ? checkedMember.name : "（未選択）"}</Accordion.Header>
        <Accordion.Body>
          <Row className="mb-2">
            {/* <Col md="4">
              <div className="--bold mb-2">部署</div>
              {sections.map((s) => (
                <Form.Check
                  key={s.id}
                  checked={state.checkedsectionCodes.includes(`${s.id}`)}
                  id={`${s.id}`}
                  type="checkbox"
                  label={s.name}
                  onChange={() => selectSection(`${s.id}`)}
                />
              ))}
            </Col> */}
            <Col className="--font-s">
              ※デフォルトでは部下と本人が表示されます。他のユーザーを検索する場合はキーワードを入力してください。
            </Col>
            <Col md="12" className="mt-4 mt-md-0">
              <Form.Control
                type="text"
                id="search"
                value={state.keyword}
                className="mb-2"
                placeholder="キーワード（名前・ログインID）で絞り込む"
                onChange={(e) => {
                  onChangeKeyWord(e.target.value);
                }}
              />
              <Row>
                {filteredAccounts.length > 0 ? (
                  filteredAccounts.map((t) => (
                    <Col md="6" key={`filteredAccounts_${t.id}`}>
                      <Form.Check
                        checked={selectedAccountId === t.id}
                        id={`${t.id}`}
                        type="radio"
                        label={`${t.name}（${t.loginCode}）${!t.isActive ? "（無効）" : ""}`}
                        onChange={() => selectTarget(t.id)}
                      />
                    </Col>
                  ))
                ) : (
                  <Col>キーワードに該当するアカウントが存在しません。キーワードを変更してください。</Col>
                )}
                {filteredAccounts.length === ACCOUNT_SEARCH_LIMIT && (
                  <Col>{`${ACCOUNT_SEARCH_LIMIT}名以上存在します。選択したいアカウントが表示されていない場合はキーワードを変更してください。`}</Col>
                )}
              </Row>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default MemberSelector;
