import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import "react-calendar/dist/Calendar.css";
import { getMyNumberDownloadLogs, selectDownloadLogsWithAccount } from "../myNumber/myNumberSlice";
import Sidebar from "../../component/Sidebar";
import Table from "../../component/Table";
import { getMembers } from "../profile/profileSlice";

function MyNumberDownloadLogs() {
  const dispatch = useAppDispatch();
  const downloadLogs = useAppSelector(selectDownloadLogsWithAccount);
  useEffect(() => {
    dispatch(getMyNumberDownloadLogs());
    dispatch(getMembers());
  }, []);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"masters"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">マイナンバー - 一括ダウンロード履歴</h1>
        <main className="mt-3 py-4 px-md-2 bg-white">
          <Container>
            <Row className="mt-2">
              <Col>
                {downloadLogs.length > 0 ? (
                  <Table
                    col={[
                      {
                        name: "ワンタイムパスワード発行日時",
                      },
                      {
                        name: "ダウンロード日時",
                      },
                      {
                        name: "アカウント",
                        filterable: true,
                      },
                      {
                        name: "利用目的",
                      },
                    ]}
                    row={downloadLogs.map((l) => {
                      return {
                        data: [
                          l.issuedTimeLabel,
                          l.downloadedTimeLabel,
                          l.account ? <Link to={`/_/profile/${l.account.id}`}>{l.account.name}</Link> : "",
                          l.purpose,
                        ],
                      };
                    })}
                    useKeywordFilter={true}
                    usePagenation={true}
                  />
                ) : (
                  <Alert variant="info">一括ダウンロード履歴はありません。</Alert>
                )}
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    </div>
  );
}

export default MyNumberDownloadLogs;
