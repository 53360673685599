import { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getMembers } from "../profile/profileSlice";
import MyNumberRow from "./MyNumberRow";
import MyNumberLogsModal from "./MyNumberLogsModal";
import {
  getMyNumber,
  getMyNumberViews,
  selectMyNumberViewPerAccount,
  selectMyNumberState,
  MyNumber,
  getFamilyData,
} from "./myNumberSlice";
import Sidebar from "../../component/Sidebar";
import { useNavigate, useParams } from "react-router-dom";

function MyNumberAdminDetailView() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { accountId } = useParams();
  const { myNumberViews } = useAppSelector(selectMyNumberState);
  const views = useAppSelector(selectMyNumberViewPerAccount);
  const [myNumberMap, $myNumberMap] = useState<{ [id: string]: string }>({});
  const [isLogModalActive, $isLogModalActive] = useState(false);
  const [targetMyNumber, $targetMyNumber] = useState<MyNumber | undefined>(undefined);
  const [targetAccountName, $targetAccountName] = useState("");

  useEffect(() => {
    if (accountId) {
      dispatch(getMembers({ accountId: +accountId }));
      dispatch(getMyNumberViews({ accountId: +accountId }));
      dispatch(getFamilyData({ account_id: +accountId }));
    }
  }, [accountId]);
  const matchedView = useMemo(() => {
    if (!accountId) return null;
    return views.find((a) => a.accountId === +accountId);
  }, [views, accountId]);

  const handler = ({ id: myNumberId, action, name }: { id: number; action: string; name: string }) => {
    if (action === "browse") {
      dispatch(getMyNumber({ id: myNumberId })).then((res: any) => {
        $myNumberMap({
          ...myNumberMap,
          [`_${myNumberId}`]: res.payload,
        });
      });
    } else if (action === "register") {
      const dummyMyNumberView = (() => {
        const data = views.find((a) => a.accountId === matchedView?.accountId);
        if (!data) return;
        const matched = [data.self, data.spouse, ...data.dependent].find((d) => d.myNumber?.id === myNumberId);
        return matched?.myNumber;
      })();
      if (!dummyMyNumberView) return;
      const [entity, entityId] = (() => {
        if (dummyMyNumberView.spouse_id) return ["spouse", dummyMyNumberView.spouse_id];
        else if (dummyMyNumberView.dependent_id) return ["dependent", dummyMyNumberView.dependent_id];
        else return ["account", dummyMyNumberView.account_id];
      })();
      navigate(`/_/my_number/admin/${matchedView?.accountId}/edit/${entity}/${entityId}`);
    } else if (action === "review") {
      const matched = myNumberViews.find((v) => v.id === myNumberId);
      if (!matched) return;
      const [entity, entityId] = (() => {
        if (matched.spouse_id) return ["spouse", matched.spouse_id];
        else if (matched.dependent_id) return ["dependent", matched.dependent_id];
        else return ["account", matched.account_id];
      })();
      navigate(`/_/my_number/admin/${matchedView?.accountId}/edit/${entity}/${entityId}`);
    } else if (action === "list_logs") {
      const matched = myNumberViews.find((v) => v.id === myNumberId);
      if (!matched) return;
      $targetMyNumber(matched);
      $targetAccountName(name);
      $isLogModalActive(true);
    }
  };

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"my_number"} />
      </div>
      {matchedView && (
        <div className="Layout__main">
          <h1 className="Headline--page">マイナンバー - {matchedView.self.account?.name}</h1>
          <main className="mt-3 py-4 px-md-2 bg-white">
            <Container>
              <Row>
                <Col>
                  <Alert variant="info">マイナンバーについての操作履歴は全て記録されます。</Alert>
                </Col>
              </Row>
              {matchedView ? (
                <Row>
                  <Col>
                    <h2 className="Headline--section-sub">本人のマイナンバー</h2>
                    <MyNumberRow
                      id={matchedView.self.myNumber?.id ?? 0}
                      value={myNumberMap[`_${matchedView.self.myNumber?.id ?? 0}`]}
                      name={matchedView.self.account?.name ?? ""}
                      status={matchedView.self.myNumber?.status ?? ""}
                      status_reason={matchedView.self.myNumber?.status_reason}
                      canReview={true}
                      handler={handler}
                    />
                  </Col>
                </Row>
              ) : null}
              <Row className="--align-items-center mt-4">
                <Col>
                  <h2 className="Headline--section-sub">配偶者・家族のマイナンバー</h2>
                  {matchedView.spouse.account.name || matchedView.dependent.some((d) => d.account.name) ? (
                    <div>
                      {matchedView.spouse?.account.name && matchedView.spouse?.myNumber && (
                        <MyNumberRow
                          id={matchedView.spouse.myNumber.id}
                          value={myNumberMap[`_${matchedView.spouse.myNumber.id}`]}
                          name={matchedView.spouse.account.name}
                          status={matchedView.spouse.myNumber.status}
                          status_reason={matchedView.spouse.myNumber.status_reason}
                          canReview={true}
                          handler={handler}
                          className="mb-1"
                        />
                      )}
                      {matchedView.dependent.length > 0 &&
                        matchedView.dependent.map((d) => {
                          if (!d.account || !d.myNumber) return null;
                          return (
                            <MyNumberRow
                              key={d.myNumber.id}
                              id={d.myNumber.id}
                              value={myNumberMap[`_${d.myNumber.id}`]}
                              name={d.account.name}
                              status={d.myNumber.status}
                              status_reason={d.myNumber.status_reason}
                              canReview={true}
                              handler={handler}
                              className="mb-1"
                            />
                          );
                        })}
                    </div>
                  ) : (
                    <span>配偶者・家族は登録されていません。</span>
                  )}
                </Col>
              </Row>
              <MyNumberLogsModal
                isActive={isLogModalActive}
                myNumber={targetMyNumber}
                name={targetAccountName}
                onClose={() => {
                  $isLogModalActive(false);
                  $targetAccountName("");
                }}
              />
            </Container>
          </main>
        </div>
      )}
    </div>
  );
}

export default MyNumberAdminDetailView;
