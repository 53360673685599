import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { download } from "../../app/download";
import { store, RootState } from "../../app/store";
import {
  Member,
  Account,
  Profile,
  GuestAccount,
  UploadingAccountData,
  profileSectorStaff,
  tablesOfCategory,
  SearchCondition,
  USER_TABLE_PREFIX,
  ACCOUNT_SEARCH_LIMIT,
  PREVIEW_LIST_UPTO,
  MultiTablesDownloadPolicy,
  DiffDownloadPolicy,
  accountFieldTerms,
} from "./profileValues";
import {
  ProfileField,
  FieldValue,
  ProfileSubFieldDiff,
  toProfileField,
  ProfileSubField,
  ProfileFile,
  ProfileFileElement,
} from "./profileFieldValues";
import { serviceLabels } from "../permission/permissionValues";
import { call, callCollaborator } from "../../app/api";
import dayjs from "dayjs";
import { RegularColumn, SectorRegularColumns } from "../client/clientValues";
import { DecodedFileData } from "../../component/Uploader";

const SLICE_NAME = "profile";
export interface ProfileState {
  selfAccount?: Account;
  members: Member[];
  selectedMember: Member | null;
  accounts: Account[];
  filteredAccounts: Account[];
  selectedAccounts: Account[];
  editingProfile: Profile | null;
  profileFields: ProfileField[];
  profileFiles: ProfileFile[];
  previewData: string[][];
  previewColumns?: string[];
  previewMetaData: { id: number; account_id: number }[];
  previewTotalCount: number;
  validation: [string, string][]; // SubField へのパス、エラーメッセージのセット
  previewHasMore: boolean;
  fetchedProfilePage: number;
  permissionsByTargetAccount: {
    [account_id: string]: {
      [sector: string]: {
        is_edit_permitted: boolean;
        is_view_permitted: Boolean;
      };
    };
  };
  searchConditions: any[];
  accountLoading: boolean;
  allAccountsLoaded: boolean;
}

/*
  state の初期状態
*/
const initialState: ProfileState = {
  selfAccount: undefined,
  members: [],
  selectedMember: null,
  accounts: [],
  filteredAccounts: [],
  selectedAccounts: [],
  editingProfile: null,
  profileFields: [],
  profileFiles: [],
  previewData: [],
  previewColumns: [],
  previewMetaData: [],
  previewTotalCount: 0,
  validation: [],
  previewHasMore: false,
  fetchedProfilePage: 1,
  permissionsByTargetAccount: {},
  searchConditions: [],
  accountLoading: false,
  allAccountsLoaded: false,
};

export const selectMember = createAsyncThunk(SLICE_NAME + "/selectMember", async ({ id }: { id: number }) => {
  const members: Member[] = store.getState().profile.members;
  return members.find((m) => m.id === id) ?? null;
});

const _toProfileFile = (results: { [key: string]: any }[]) => {
  return results.map((result) => {
    return {
      id: result.id,
      owner: result.owner,
      files: result.files as ProfileFileElement[],
    } as ProfileFile;
  });
};

/*
  レスポンスから作成可能な ProfileField を生成する
*/
const _toProfileFields = (
  results: { [key: string]: any }[],
  table: string,
  categoryId: string,
  activeFieldType: string
) => {
  let profileFieldsList = [] as ProfileField[];
  for (const subGroup in profileSectorStaff) {
    if (profileSectorStaff[subGroup].table !== table) continue;
    if (profileSectorStaff[subGroup].category === "") continue;
    // 履歴／詳細の表示切替え対応
    if (categoryId === profileSectorStaff[subGroup].category) {
      const subFieldsKeys = Object.keys(profileSectorStaff[subGroup]["subFields"]);
      if (
        activeFieldType === "detail" &&
        (subFieldsKeys.some((key) => key.indexOf("_history") !== -1) ||
          subFieldsKeys.some((key) => key.indexOf("_summary") !== -1))
      ) {
        continue;
      } else if (
        activeFieldType === "history" &&
        // 履歴情報をもつテーブルのみ
        Object.keys(profileSectorStaff).some((key) => key.indexOf(subGroup) !== -1 && key.indexOf("_history") !== -1) &&
        !subFieldsKeys.some((key) => key.indexOf("_history") !== -1) &&
        !subFieldsKeys.some((key) => key.indexOf("_summary") !== -1)
      ) {
        continue;
      }
    }
    const profileFields = profileSectorStaff[subGroup].toProfileFields(results);
    profileFieldsList = [...profileFieldsList, ...profileFields];
  }
  return profileFieldsList;
};

export const getUserData = createAsyncThunk(
  SLICE_NAME + "/getUserData",
  async ({
    activeFieldType,
    categoryId,
    baseDate,
    accountId,
    uniqueKeysConditions,
  }: {
    activeFieldType: "detail" | "history";
    categoryId: string;
    baseDate?: string; // e.g.) 2019-10-01
    accountId?: number;
    uniqueKeysConditions?: {};
  }) => {
    if (!baseDate && !accountId) return;

    /*
      アカウント別の ProfileField[] をまとめる
    */
    const assort = (fieldList: ProfileField[][]) => {
      return fieldList
        .flat()
        .flat()
        .reduce((prev, current) => {
          const key = `_${current.account.id}`;
          prev[key] = prev[key] ?? [];
          prev[key].push(current);
          return prev;
        }, {} as { [key: string]: any });
    };

    if (!tablesOfCategory[categoryId]) return { fields: [] };
    // 詳細表示ではサマリは表示しない
    const tables =
      activeFieldType === "detail"
        ? tablesOfCategory[categoryId].tables.filter((t) => t.indexOf("_summary") === -1)
        : tablesOfCategory[categoryId].tables;
    const resultPerAccounts = await Promise.all(
      tables.map((table) => {
        return new Promise<ProfileField[]>(async (resolve, reject) => {
          try {
            const res = await call(
              "get",
              `user_data_manager/${table}`
            )({
              account_id: accountId,
              base_date: baseDate,
              ...uniqueKeysConditions,
            });
            // - 通常、複数レコードはそれぞれ別のアカウントに対応する
            // - １アカウントに多対応なレコードの場合、アカウントごとに
            resolve(_toProfileFields(res.data.result, table, categoryId, activeFieldType));
          } catch (e) {
            reject(e);
          }
        });
      })
    );
    const assorted = assort(resultPerAccounts);
    const fields = Object.keys(assorted).length > 0 ? (assorted[Object.keys(assorted)[0]] as ProfileField[]) : [];
    let files = [] as ProfileFile[];
    if (fields.some((field) => field.subFields.some((subField) => subField.type === "file"))) {
      let ids = [] as FieldValue[];
      fields.forEach((field) => {
        field.subFields
          .filter(({ type, value }) => type === "file" && value)
          .forEach(({ value }) => {
            ids.push(value);
          });
      });
      if (ids.length > 0) {
        const res = await call(
          "get",
          "file_manager/attach"
        )({
          id__in: ids,
        });
        files = _toProfileFile(res.data.result);
      }
    }
    return {
      fields: fields, // TODO
      files,
    };
  }
);

export const getUserDataById = createAsyncThunk(
  SLICE_NAME + "/getUserDataById",
  async ({ table, id, accountId }: { table: string; id: number; accountId: number }) => {
    const res = await call("get", `user_data_manager/${table}`)({ id, account_id: accountId });
    return res.data.result[0];
  }
);

// 編集内容が既存のデータと重複していないかチェックする。stateに入れない
export const checkDuplication = createAsyncThunk(
  SLICE_NAME + "/checkDuplication",
  async ({
    table,
    accountId,
    uniqueKeysConditions,
  }: {
    table: string;
    accountId?: number;
    uniqueKeysConditions?: {};
  }) => {
    // 取得条件
    let conditions = {
      account_id: accountId,
    } as { [key: string]: any };
    if (uniqueKeysConditions && Object.keys(uniqueKeysConditions).length > 0) {
      // 履歴行からの詳細表示ではユニークキーによる条件を指定
      conditions = { ...conditions, ...uniqueKeysConditions };
    }
    const res = await call("get", `user_data_manager/${table}`)({ ...conditions });
    const result: { [key: string]: boolean } = {
      isDuplicated: res.data.result.length > 0,
    };
    return result;
  }
);

export const deleteUserData = createAsyncThunk(
  SLICE_NAME + "/deleteUserData",
  async (option: {
    table: string;
    data: {
      id: number;
    };
  }) => {
    if (!option?.table) return;
    await call(
      "delete",
      `user_data_manager/${option.table}`
    )({
      id: option.data.id,
    });
    return;
  }
);
export const commitUserData = createAsyncThunk(
  SLICE_NAME + "/commitUserData",
  async (option: {
    activeFieldType: "detail" | "history";
    categoryId: string;
    table: string;
    accountLoginCode?: string;
    data: ProfileSubFieldDiff[];
  }) => {
    if (!option?.table) return;
    const updateDataPerRecords: {
      [recordId: string]: { [field: string]: FieldValue };
    } = {};
    option.data.forEach(({ subFieldName, value, recordId, toDelete }) => {
      updateDataPerRecords[recordId] = updateDataPerRecords[recordId] || {};
      if (toDelete === true) {
        updateDataPerRecords[recordId].toDelete = true;
      } else {
        updateDataPerRecords[recordId][subFieldName] = value === "" ? null : value;
      }
    });
    const results = await Promise.all(
      Object.keys(updateDataPerRecords).map((recordId) => {
        const method = ((_recordId) => {
          if (_recordId < 0) {
            return updateDataPerRecords[_recordId].toDelete !== true ? "post" : "";
          } else {
            return updateDataPerRecords[_recordId].toDelete ? "delete" : "put";
          }
        })(+recordId);
        if (!method) {
          return new Promise<void>((resolve) => resolve());
        }
        const toRequestBody = (() => {
          for (const subGroup in profileSectorStaff) {
            if (profileSectorStaff[subGroup].table === option.table) return profileSectorStaff[subGroup].toRequestBody;
          }
        })();
        if (!toRequestBody) {
          throw new Error("matching subset not found");
        }
        const body = (() => {
          if (method === "put") {
            return toRequestBody.put(updateDataPerRecords[recordId], +recordId);
          } else if (method === "post") {
            if (!option.accountLoginCode) {
              throw new Error("accountLoginCode in required to post data");
            }
            return toRequestBody.post(
              updateDataPerRecords[recordId],
              option.accountLoginCode,
              updateDataPerRecords[recordId].valid_from
                ? `${updateDataPerRecords[recordId].valid_from}`
                : dayjs().format("YYYY-MM-DD"),
              updateDataPerRecords[recordId].valid_to ? `${updateDataPerRecords[recordId].valid_to}` : "9999-12-31"
            );
          } else {
            return { id: +recordId };
          }
        })();
        return call(method, `user_data_manager/${option.table}`)(body);
      })
    );
    const profileFields = _toProfileFields(
      results.map((r) => r.data.result[0]),
      option.table,
      option.categoryId,
      option.activeFieldType
    );
    return { fields: profileFields };
  }
);

export const attachFile = createAsyncThunk(
  SLICE_NAME + "/attachFile",
  async (option: {
    activeFieldType: "detail" | "history";
    categoryId: string;
    table: string;
    accountLoginCode?: string;
    subField: ProfileSubField;
    decodedFileData: DecodedFileData;
  }) => {
    if (!option?.table || option?.subField.record.id < 0) return;
    const body = (() => {
      if (option.subField.value) {
        return {
          id: option.subField.value,
          name: option.decodedFileData.name,
          file: option.decodedFileData.dataURI,
        };
      } else {
        return { name: option.decodedFileData.name, file: option.decodedFileData.dataURI };
      }
    })();
    const res1 = await call("post", "file_manager/attach")(body);
    if (!res1.data.result) {
      throw new Error("file attachments failed");
    }
    const fileRecord: ProfileFile = res1.data.result[0];
    if (option.subField.value) return { file: fileRecord };
    const updateDataPerRecords = {
      [option.subField.record.id]: {
        [option.subField.id]: fileRecord.id,
      },
    } as {
      [recordId: string]: { [field: string]: FieldValue };
    };
    updateDataPerRecords[option.subField.record.id][option.subField.id] = fileRecord.id;
    const results = await Promise.all(
      Object.keys(updateDataPerRecords).map((recordId) => {
        const toRequestBody = (() => {
          for (const subGroup in profileSectorStaff) {
            if (profileSectorStaff[subGroup].table === option.table) return profileSectorStaff[subGroup].toRequestBody;
          }
        })();
        if (!toRequestBody) {
          throw new Error("matching subset not found");
        }
        const body = (() => {
          return toRequestBody.put(updateDataPerRecords[recordId], +recordId);
        })();
        return call("put", `user_data_manager/${option.table}`)(body);
      })
    );
    const subField = { ...option.subField, value: fileRecord.id };
    return { subField, file: fileRecord };
  }
);

export const downloadFile = createAsyncThunk(
  SLICE_NAME + "/downloadFile",
  async ({ fileId, key }: { fileId: string; key: string }) => {
    if (!fileId || !key) return;
    const res = await call(
      "get",
      "file_manager/download"
    )({
      id: fileId,
      key: key,
    });
    if (!res.data.result) {
      throw new Error("file download failed");
    }
    const { file, file_name, mime } = res.data.result;
    download({
      file,
      fileName: file_name,
      mimeType: mime,
    });
  }
);

export const deleteFile = createAsyncThunk(
  SLICE_NAME + "/deleteFile",
  async ({ fileId, key }: { fileId: string; key: string }) => {
    if (!fileId || !key) return;
    const res = await call(
      "delete",
      "file_manager/attach"
    )({
      id: fileId,
      key: key,
    });
    if (!res.data.result) {
      throw new Error("file delete failed");
    }
    const fileRecord: ProfileFile = res.data.result[0];
    return { file: fileRecord };
  }
);

const _getTerms = (regularColumns: RegularColumn[], exceptionSubFieldIds?: string[]) => {
  return regularColumns
    .filter((col) => {
      return !exceptionSubFieldIds?.includes(col.id);
    })
    .reduce((obj, col) => ({ ...obj, [col.id]: col.label }), {});
};
/*
  対象者、基準期間、フィールド　を指定し
  表として出力可能な形式のデータを取得する。
*/
export const getProfileListView = createAsyncThunk(
  SLICE_NAME + "/getProfileListView",
  async ({
    memberIds,
    excludedMemberIds,
    table,
    exceptionSubFieldIds,
    baseDate,
    sectorRegularColumns,
    conditions,
    checkedsectionCodes,
    enrollmentTypeOptions,
    page,
  }: {
    memberIds: number[];
    excludedMemberIds: number[];
    table: string;
    exceptionSubFieldIds?: string[];
    baseDate: dayjs.Dayjs;
    sectorRegularColumns: SectorRegularColumns;
    conditions: SearchCondition;
    checkedsectionCodes: string[];
    enrollmentTypeOptions: string[];
    page: number;
  }) => {
    const tableData = [] as string[][];
    const metaData = [] as { id: number; account_id: number }[];
    let columns = [] as string[];
    if (memberIds.length === 0 && (excludedMemberIds.length > 0 || checkedsectionCodes.length > 0))
      return { tableData, metaData };
    const res = await call(
      "get",
      `user_data_manager/${table}`
    )({
      ...conditions,
      account_id__in: memberIds,
      enrollment_type__in: enrollmentTypeOptions,
      base_date: table.indexOf("_summary") !== -1 ? undefined : baseDate.format("YYYY-MM-DD"),
      limit: PREVIEW_LIST_UPTO,
      page: page,
    });
    const previewTotalCount = res.data.count;
    const membersWithProfiles = res.data.result;
    const previewHasMore = res.data.has_more;
    const fetchedProfilePage = page;
    if (membersWithProfiles.length > 0) {
      const regularColumns = sectorRegularColumns[`${USER_TABLE_PREFIX}${table}`];
      const terms = _getTerms(regularColumns, exceptionSubFieldIds);
      if (page === 1) {
        tableData.push(Object.values(terms));
        columns = Object.keys(terms);
      }
      membersWithProfiles.forEach((profile: any) => {
        tableData.push(Object.keys(terms).map((key) => profile[key]));
        metaData.push({ id: profile.id, account_id: profile.account_id });
      });
    }
    return { tableData, columns, metaData, previewTotalCount, previewHasMore, fetchedProfilePage };
  }
);

export const getSelfAccount = createAsyncThunk(
  SLICE_NAME + "/getSelfAccount",
  async ({ account_id }: { account_id: number }) => {
    if (account_id <= 0) throw new Error("account_id is invalid");
    const res = await call("get", "account_manager/account_view")({ account_id });
    const accounts: Account[] = res.data.result
      .map((a: any) => {
        return {
          id: a.account_id,
          name: a.name,
          image: a.image ?? "",
          isActive: a.is_active ?? false,
          isBilling: a.is_billing ?? false,
          isRemind: a.is_remind ?? false,
          language: a.language,
          loginCode: a.login_code,
          mailAddress: a.mail_address,
          mainCompanyCode: a.main_company_code,
          remarks: a.remarks ?? "",
          statusMessage: a.status_message,
        } as Account;
      })
      .sort((a: Account, b: Account) => {
        return a.id - b.id;
      });
    return { account: accounts[0] };
  }
);

export const getMembers = createAsyncThunk(SLICE_NAME + "/getMembers", async (options?: { accountId: number }) => {
  const res = await call(
    "get",
    "account_manager/account_view"
  )(
    options?.accountId
      ? {
          account_id: options.accountId,
        }
      : undefined
  );
  const accounts: Account[] = res.data.result
    .map((a: any) => {
      return {
        id: a.account_id,
        name: a.name,
        image: a.image ?? "",
        isActive: a.is_active ?? false,
        isBilling: a.is_billing ?? false,
        isRemind: a.is_remind ?? false,
        language: a.language,
        loginCode: a.login_code,
        mailAddress: a.mail_address,
        mainCompanyCode: a.main_company_code,
        statusMessage: a.status_message ?? "",
        remarks: a.remarks ?? "",
      } as Account;
    })
    .sort((a: Account, b: Account) => {
      return a.id - b.id;
    });
  const res2 = await call(
    "get",
    `user_data_manager/assignment2`
  )(
    options?.accountId
      ? {
          base_date: dayjs().format("YYYY-MM-DD"),
          account_id: options.accountId,
        }
      : {
          base_date: dayjs().format("YYYY-MM-DD"),
        }
  );
  const members = res2.data.result
    .map((r: any) => {
      const thisAccount = accounts.find((a) => a.id === r.account_id);
      if (!thisAccount) return null;
      return {
        id: thisAccount.id,
        name: thisAccount.name,
        image: thisAccount.image ?? "",
        sectionCode: r.section_code,
        sectionName: r.section_name,
        fullSectionName: r.full_section_name,
        positionCode: r.position_code,
        positionName: r.position_name,
        isConcurrent: r.is_concurrent,
      } as Member;
    })
    .filter((_: any) => _)
    .sort((a: Member, b: Member) => {
      return a.id - b.id;
    });
  return { accounts, members, allAccountsLoaded: !options?.accountId };
});

export const putAccount = createAsyncThunk(
  SLICE_NAME + "/putAccount",
  async (options: {
    id: number;
    name: string;
    loginCode: string;
    mainCompanyCode: string;
    mailAddress: string;
    language: string;
    remarks: string;
    isActive: boolean;
    isRemind: boolean;
    image: string;
  }) => {
    const res = await call(
      "put",
      "account_admin_manager/account"
    )({
      id: options.id,
      name: options.name,
      login_code: options.loginCode,
      main_company_code: options.mainCompanyCode,
      mail_address: options.mailAddress,
      language: options.language,
      remarks: options.remarks,
      status_message: "1",
      is_active: options.isActive,
      is_remind: options.isRemind,
      image: options.image,
    });
    const r = res.data.result[0];
    const updated = {
      id: r.id,
      name: r.name,
      image: r.image,
      isActive: r.is_active,
      isBilling: r.is_billing,
      isRemind: r.is_remind,
      language: r.language,
      loginCode: r.login_code,
      mailAddress: r.mail_address,
      mainCompanyCode: r.main_company_code,
      remarks: r.remarks,
      statusMessage: r.status_message,
    } as Account;
    return updated;
  }
);

export const postAccount = createAsyncThunk(
  SLICE_NAME + "/postAccount",
  async (options: {
    name: string;
    loginCode: string;
    mailAddress: string;
    password: string;
    language: string;
    remarks: string;
    mainCompanyCode: string;
    isActive: boolean;
    isRemind: boolean;
    image: string;
  }) => {
    const res = await call(
      "post",
      "account_admin_manager/account"
    )({
      name: options.name,
      login_code: options.loginCode,
      mail_address: options.mailAddress,
      password: options.password,
      language: options.language,
      remarks: options.remarks,
      status_message: "",
      main_company_code: options.mainCompanyCode,
      is_active: options.isActive,
      is_remind: options.isRemind,
      image: options.image,
    });
    const r = res.data.result[0];
    const created = {
      id: r.id,
      name: r.name,
      image: r.image,
      isActive: r.is_active,
      isBilling: r.is_billing,
      isRemind: r.is_remind,
      language: r.language,
      loginCode: r.login_code,
      mailAddress: r.mail_address,
      mainCompanyCode: r.main_company_code,
      remarks: r.remarks,
      statusMessage: r.status_message,
    } as Account;
    return created;
  }
);

export const searchAccounts = createAsyncThunk(
  SLICE_NAME + "/searchAccounts",
  async (conditions: SearchCondition = {}) => {
    const params = {} as SearchCondition;
    if (conditions && conditions["keyword"])
      params.or = [{ name__contain: conditions.keyword, login_code__contain: conditions.keyword }];
    if (conditions && conditions["accountId"]) {
      params.account_id = conditions["accountId"];
    }
    params.sort_by = ["login_code"];
    params.limit = ACCOUNT_SEARCH_LIMIT;
    const res = await call("get", "account_manager/account_view")(params);
    const accounts = res.data.result.map((r: any) => ({
      id: r.account_id,
      name: r.name,
      image: r.image,
      isActive: r.is_active,
      isBilling: r.is_billing,
      isRemind: r.is_remind,
      language: r.language,
      loginCode: r.login_code,
      mailAddress: r.mail_address,
      mainCompanyCode: r.main_company_code,
      remarks: r.remarks,
      statusMessage: r.status_message,
    })) as Account[];
    return accounts;
  }
);

export const getMyStaff = createAsyncThunk(SLICE_NAME + "/getMyStaff", async (conditions: SearchCondition = {}) => {
  const params = {} as SearchCondition;
  params.sort_by = ["login_code"];
  params.limit = ACCOUNT_SEARCH_LIMIT;
  const res = await call("get", "account_manager/my_staff")(params);
  const accounts = res.data.result.map((r: any) => ({
    id: r.id,
    name: r.name,
    image: r.image,
    isActive: r.is_active,
    isBilling: r.is_billing,
    isRemind: r.is_remind,
    language: r.language,
    loginCode: r.login_code,
    mailAddress: r.mail_address,
    mainCompanyCode: r.main_company_code,
    remarks: r.remarks,
    statusMessage: r.status_message,
  })) as Account[];
  return accounts;
});

export const getAccounts = createAsyncThunk(SLICE_NAME + "/getAccounts", async (accountIds: number[]) => {
  const params = {} as SearchCondition;
  params.account_id__in = accountIds;
  params.sort_by = ["login_code"];
  const res = await call("get", "account_manager/account_view")(params);
  const accounts = res.data.result.map((r: any) => ({
    id: r.account_id,
    name: r.name,
    image: r.image,
    isActive: r.is_active,
    isBilling: r.is_billing,
    isRemind: r.is_remind,
    language: r.language,
    loginCode: r.login_code,
    mailAddress: r.mail_address,
    mainCompanyCode: r.main_company_code,
    remarks: r.remarks,
    statusMessage: r.status_message,
  })) as Account[];
  return accounts;
});

export const searchAccountWithToken = createAsyncThunk(
  SLICE_NAME + "/searchAccountWithToken",
  async (token: string) => {
    const res = await callCollaborator(
      "get",
      "company_manager/account_token"
    )({
      token,
    });
    const data = res.data.results[0].result[0];
    if (!data) {
      throw new Error("No guest account found");
    }

    const res2 = await callCollaborator(
      "get",
      "company_manager/company_member"
    )({
      account_id: data["account.id"],
    });
    const data2 = res2.data.results[0].result[0];
    const isCompanyMember = !!data2;

    const guestAccount = {
      id: data["account.id"],
      isActive: data["account.is_active"],
      language: data["account.language"],
      mailAddress: data["account.mail_address"],
      name: data["account.name"],
      companyName: data["company_name"],
      rolesInCurrentCompany: data.role as {
        id: number;
        role_name: string;
        service_id: number;
      }[],
    } as GuestAccount;

    return {
      account: guestAccount,
      isCompanyMember,
    };
  }
);

export const reissuePassword = createAsyncThunk(
  SLICE_NAME + "/reissuePassword",
  async ({ account, mainCompanyName }: { account: Account; mainCompanyName: string }) => {
    if (!account) return { ok: false };
    const res = await callCollaborator(
      "put",
      "account_manager/reissue_password_other"
    )({
      account_id: account.id,
      account_main_company_code: account.mainCompanyCode,
      account_main_company_name: mainCompanyName,
      account_name: account.name,
      account_login_code: account.loginCode,
      account_mail_address: account.mailAddress,
      account_language: account.language,
    });
    return { ok: res.data.results[0].result.finished === 1 };
  }
);

export const downloadAccounts = createAsyncThunk(
  SLICE_NAME + "/downloadAccounts",
  async ({ format, companyId }: { format: "xlsx" | "csv"; companyId: number }) => {
    if (!format || !companyId) return;
    const res = await call(
      "get",
      "company_admin_manager/service_contract"
    )({
      company_id: companyId,
      is_active: true,
    });
    const _accountFieldTerms = { ...accountFieldTerms };
    res.data.result.forEach((resService: any) => {
      _accountFieldTerms[resService["service.name"]] = serviceLabels[resService["service.name"]].label;
    });
    const res2 = await call(
      "get",
      "account_manager/account_view"
    )({
      format,
      terms: _accountFieldTerms,
    });
    const { file, mime } = res2.data.result;
    download({
      file,
      fileName: `account.${format}`,
      mimeType: mime,
    });
  }
);

export const uploadAccounts = createAsyncThunk(
  SLICE_NAME + "/uploadAccounts",
  async ({ post_data, put_data }: { post_data: UploadingAccountData[]; put_data: UploadingAccountData[] }) => {
    if (post_data.length === 0 && put_data.length === 0) return;
    const res = await callCollaborator(
      "post",
      "company_manager/account_uploader"
    )({
      post_data,
      put_data,
      next_process: {
        externalEffect: "_getMembers",
        externalEffectUsed: false,
        payload: {
          url: "/account/",
        },
      },
    });
  }
);

/*
  @validatePermissions
*/
export const validatePermissions = createAsyncThunk(
  SLICE_NAME + "/validatePermissions",
  async ({ account_id }: { account_id: number }) => {
    const res = await call("get", "profile_manager/validate_permission")({ account_id });
    const result = res.data.result as any[];
    const permissions = result?.length > 0 ? result[0] : {};
    return { account_id, permissions };
  }
);

export const downloadProfileListView = createAsyncThunk(
  SLICE_NAME + "/downloadProfileListView",
  async ({
    memberIds,
    excludedMemberIds,
    table,
    baseDate,
    conditions,
    enrollmentTypeOptions,
  }: {
    memberIds: number[];
    excludedMemberIds: number[];
    table: string;
    baseDate: dayjs.Dayjs;
    conditions: SearchCondition;
    enrollmentTypeOptions: string[];
  }) => {
    if (memberIds.length === 0 && excludedMemberIds.length > 0) return;
    const res = await call(
      "get",
      `user_data_manager/${table}`
    )({
      ...conditions,
      base_date: table.indexOf("_summary") !== -1 ? undefined : baseDate.format("YYYY-MM-DD"),
      account_id__in: memberIds,
      enrollment_type__in: enrollmentTypeOptions,
      format: "xlsx",
    });
    const { file, file_name } = res.data.result;
    download({
      file,
      fileName: file_name,
      mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  }
);

export const downloadMultiTableList = createAsyncThunk(
  SLICE_NAME + "/downloadMultiTableList",
  async ({
    date,
    policy,
    tables,
    options = {},
  }: {
    date: dayjs.Dayjs;
    policy: MultiTablesDownloadPolicy;
    tables: string[];
    options: {
      [key: string]: boolean | string | string[];
    };
  }) => {
    await call(
      "get",
      `user_data_download_manager/multiple_download`
    )({
      date: date.format("YYYY-MM-DD"),
      policy,
      tables,
      options,
    });
  }
);

export const downloadDiffList = createAsyncThunk(
  SLICE_NAME + "/downloadDiffList",
  async ({
    dateFrom,
    dateTo,
    policy,
    tables,
    outputFormat,
    requiredColumns,
    options = {},
  }: {
    dateFrom: string;
    dateTo: string;
    policy: DiffDownloadPolicy;
    tables: string[];
    outputFormat: string;
    requiredColumns: string[];
    options: {
      [key: string]: boolean | string | string[];
    };
  }) => {
    await call(
      "get",
      `user_data_download_manager/difference_download`
    )({ dateFrom, dateTo, policy, tables, requiredColumns, outputFormat, options });
  }
);

export const postSearchCondition = createAsyncThunk(
  SLICE_NAME + "/postSearchCondition",
  async ({
    label,
    dateFrom,
    dateTo,
    policy,
    tables,
    outputFormat,
    requiredColumns,
    options = {},
  }: {
    label: string;
    dateFrom: string;
    dateTo: string;
    policy: DiffDownloadPolicy;
    tables: string[];
    outputFormat: string;
    requiredColumns: string[];
    options: {
      [key: string]: boolean | string | string[];
    };
  }) => {
    await call(
      "post",
      `user_data_download_manager/search_condition`
    )({ label, dateFrom, dateTo, policy, tables, outputFormat, requiredColumns, options });
  }
);

export const deleteSearchCondition = createAsyncThunk(
  SLICE_NAME + "/deleteSearchCondition",
  async ({ id }: { id: string }) => {
    await call("delete", `user_data_download_manager/search_condition`)({ id });
  }
);

export const getSearchCondition = createAsyncThunk(SLICE_NAME + "/getSearchCondition", async () => {
  const res = await call("get", `user_data_download_manager/search_condition`)();
  return res.data.result;
});

export const hasSectionMembers = createAsyncThunk(
  SLICE_NAME + "/hasSectionMembers",
  async ({ sectionCodes }: { sectionCodes: string[] }) => {
    const res = await call(
      "get",
      "user_data_manager/assignment2"
    )({
      base_date: dayjs().format("YYYY-MM-DD"),
      section_code__in: sectionCodes,
      page: 1,
      limit: 1,
    });
    return res.data.result.length > 0;
  }
);

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    unselectMember: (state) => {
      state.selectedMember = null;
      return state;
    },
    unselectEditingProfile: (state) => {
      state.editingProfile = null;
      return state;
    },
    unselectProfile: (state) => {
      state.profileFields = [];
      state.profileFiles = [];
      return state;
    },
    createEmptyProfile: (
      state,
      action: PayloadAction<{ category: string; accountId: number; columns: RegularColumn[] }>
    ) => {
      if (state.profileFields.length > 0 && state.profileFields[0].subFields.some((s) => s.type === "tagHandler")) {
        state.profileFields = profileSectorStaff[action.payload.category].toProfileFields(
          [{ account_id: action.payload.accountId }],
          action.payload.columns
        );
        state.profileFields = state.profileFields.map((field) => {
          const next = state.profileFields[0].subFields.map((s) => {
            return {
              ...s,
              record: {
                id: -1,
                account_id: action.payload.accountId,
                created_at: "",
                updated_at: "",
                updated_by: null,
                valid_from: "",
                valid_to: "",
              },
            };
          });
          field.subFields = [...next];
          return field;
        });
      } else {
        const _profileFields = toProfileField({
          fieldName: action.payload.category,
          base: profileSectorStaff[action.payload.category],
          columns: action.payload.columns,
        });
        _profileFields["account"] = { id: action.payload.accountId };
        state.profileFields = [_profileFields];
      }
      return state;
    },
    copyProfile: (
      state,
      action: PayloadAction<{ category: string; accountId: number; subFields: ProfileSubField[] }>
    ) => {
      state.profileFields = state.profileFields.map((field) => {
        if (field.fieldName !== action.payload.category) return field;
        const subFields = action.payload.subFields;
        const isRelational = subFields.some((s) => s.type === "tagHandler" && s.isRelational);
        const next = subFields.map((s) => {
          return {
            ...s,
            // 家族など複数レコードを一度に表示できるようにしている場合（isRelational=true）も履歴追加は1レコード毎なので、全体的にindexを1に統一する
            tagGroupsToUse: s.type === "tagHandler" && isRelational ? [1] : s.tagGroupsToUse,
            tagGroupIndex: isRelational ? 1 : s.tagGroupIndex,
            id: isRelational ? s.id.replace(`_${s.tagGroupIndex}`, "_1") : s.id,
            record: {
              id: -1,
              account_id: action.payload.accountId,
              created_at: "",
              updated_at: "",
              updated_by: null,
              valid_from: "",
              valid_to: "",
            },
          };
        });
        field.subFields = [...next];
        return field;
      });
      return state;
    },
    stageProfileSubFields: (state, action) => {
      state.profileFields = state.profileFields.map((field) => {
        if (field.fieldName !== action.payload.fieldName) return field;
        field.subFields = [...action.payload.subFields];
        return field;
      });
      return state;
    },
    clearFilteredAccounts: (state) => {
      state.filteredAccounts = [];
      return state;
    },
    clearSelectedAccounts: (state) => {
      state.selectedAccounts = [];
      return state;
    },
    addSelectedAccount: (state, action: PayloadAction<{ account: Account }>) => {
      state.selectedAccounts = [...state.selectedAccounts, action.payload.account];
      return state;
    },
    clearPreviewData: (state) => {
      state.previewData = [];
      state.previewColumns = [];
      state.previewMetaData = [];
      state.previewTotalCount = 0;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(selectMember.fulfilled, (state, action) => {
      state.selectedMember = action.payload;
    });
    builder.addCase(getMembers.pending, (state, action) => {
      state.accountLoading = true;
    });
    builder.addCase(getMembers.fulfilled, (state, action) => {
      state.accounts = action.payload.accounts;
      state.members = action.payload.members;
      state.allAccountsLoaded = action.payload.allAccountsLoaded === true;
      state.accountLoading = false;
    });
    builder.addCase(getMembers.rejected, (state) => {
      state.accountLoading = false;
    });
    builder.addCase(getSelfAccount.fulfilled, (state, action) => {
      state.selfAccount = action.payload.account;
    });
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.profileFields = [];
      if (action.payload && action.payload.fields) {
        const fields = action.payload.fields as ProfileField[];
        const next = fields
          .reduce((prev: ProfileField[], current: ProfileField) => {
            return [...prev.filter((_) => _.fieldName !== current.fieldName), current];
          }, state.profileFields)
          .sort((a: ProfileField, b: ProfileField) => {
            return (
              state.profileFields.findIndex((_) => _.fieldName === a.fieldName) -
              state.profileFields.findIndex((_) => _.fieldName === b.fieldName)
            );
          });
        state.profileFields = next;
      } else {
        state.profileFields = [];
      }
      if (action.payload && action.payload.files) {
        const files = action.payload.files;
        state.profileFiles = files;
      } else {
        state.profileFiles = [];
      }
    });
    builder.addCase(commitUserData.fulfilled, (state, action) => {
      if (action.payload) {
        const next = action.payload.fields
          .reduce((prev: ProfileField[], current: ProfileField) => {
            return [...prev.filter((_) => _.fieldName !== current.fieldName), current];
          }, state.profileFields)
          .sort((a: ProfileField, b: ProfileField) => {
            return (
              state.profileFields.findIndex((_) => _.fieldName === a.fieldName) -
              state.profileFields.findIndex((_) => _.fieldName === b.fieldName)
            );
          });
        state.profileFields = next;
      }
    });
    builder.addCase(getProfileListView.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.previewData =
        action.payload.fetchedProfilePage && action.payload.fetchedProfilePage > 1
          ? [...state.previewData, ...action.payload.tableData]
          : action.payload.tableData;
      state.previewColumns = action.payload.columns;
      state.previewMetaData =
        action.payload.fetchedProfilePage && action.payload.fetchedProfilePage > 1
          ? [...state.previewMetaData, ...action.payload.metaData]
          : action.payload.metaData;
      state.previewTotalCount = action.payload.previewTotalCount ?? 0;
      state.previewHasMore = action.payload.previewHasMore ?? false;
      state.fetchedProfilePage = action.payload.fetchedProfilePage ?? 1;
    });
    builder.addCase(searchAccounts.fulfilled, (state, action) => {
      state.filteredAccounts = action.payload;
    });
    builder.addCase(getAccounts.fulfilled, (state, action) => {
      state.selectedAccounts = action.payload;
    });
    builder.addCase(putAccount.fulfilled, (state, action) => {
      state.accounts = state.accounts.map((a) => {
        return a.id === action.payload.id ? action.payload : a;
      });
    });
    builder.addCase(validatePermissions.fulfilled, (state, action) => {
      state.permissionsByTargetAccount[action.payload.account_id] = action.payload.permissions;
    });
    builder.addCase(attachFile.fulfilled, (state, action) => {
      if (action.payload && action.payload.subField) {
        const subField = action.payload.subField;
        const next = state.profileFields.map((field) => {
          const nextSubFields = field.subFields.map((s) => (subField.id === s.id ? subField : s));
          return { ...field, subFields: nextSubFields };
        });
        state.profileFields = next;
      }
      if (action.payload && action.payload.file) {
        const fileId = action.payload.file.id;
        if (state.profileFiles.some(({ id }) => id === fileId)) {
          // ファイルを置き換える
          state.profileFiles = state.profileFiles.map((file) => {
            if (file.id === fileId) return { ...file, files: action.payload?.file.files ?? [] };
            return file;
          });
        } else {
          // ファイルを追加する
          state.profileFiles = [...state.profileFiles, action.payload.file];
        }
      }
    });
    builder.addCase(deleteFile.fulfilled, (state, action) => {
      if (action.payload && action.payload.file) {
        state.profileFiles = state.profileFiles.map((file) => {
          if (file.id === action.payload?.file.id) return { ...file, files: action.payload.file.files };
          return file;
        });
      }
    });
    builder.addCase(getSearchCondition.fulfilled, (state, action) => {
      state.searchConditions = action.payload;
    });
    builder.addCase(getMyStaff.fulfilled, (state, action) => {
      state.filteredAccounts = action.payload;
    });
  },
});

export const {
  unselectMember,
  unselectEditingProfile,
  unselectProfile,
  createEmptyProfile,
  copyProfile,
  stageProfileSubFields,
  clearFilteredAccounts,
  clearSelectedAccounts,
  addSelectedAccount,
  clearPreviewData,
} = slice.actions;

export const selectProfileState = (state: RootState) => {
  return state.profile as ProfileState;
};

export const selectIntegratedMembers = (state: RootState) => {
  return state.profile.members.reduce(
    (
      prev: {
        [key: number]: {
          id: number;
          name: string;
          assignments: { sectionCode: string; positionCode: string }[];
        };
      },
      current: Member
    ) => {
      prev[current.id] = prev[current.id] || {
        id: current.id,
        name: current.name,
        assignments: [],
      };
      prev[current.id].assignments.push({
        sectionCode: current.sectionCode.toString(),
        positionCode: current.positionCode.toString(),
      });
      return prev;
    },
    {}
  );
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
