import { RegularColumn } from "../../client/clientValues";
import {
  ProfileField,
  ProfileSubField,
  generateValidator,
  diffCollector,
  toProfileField,
  generateMultipleProfileFieldsBundler,
  ValidDateMap,
} from "../profileFieldValues";

const MAX_DEPENDENT_PER_ACCOUNT = 50;

const base = {
  category: "dependent",
  table: "dependent",
  subFields: {
    dependent: {
      type: "tagHandler",
      isRelational: true,
      tag: "dp_",
      minTagGroupsLength: 0,
      maxTagGroupsLength: MAX_DEPENDENT_PER_ACCOUNT,
    },
    dependent_name_$N: {
      tag: "dp_",
      tagGroupIndex: 1,
    },
    dependent_name_kana_$N: {
      tag: "dp_",
      tagGroupIndex: 1,
    },
    serial_$N: {
      tag: "dp_",
      editable: false,
      tagGroupIndex: 1,
    },
    gender_type_$N: {
      tag: "dp_",
      tagGroupIndex: 1,
    },
    relationship_type_$N: {
      tag: "dp_",
      tagGroupIndex: 1,
    },
    birthday_$N: { tag: "dp_", tagGroupIndex: 1 },
    age_$N: { tag: "dp_", tagGroupIndex: 1 },
    postcode_$N: { tag: "dp_", tagGroupIndex: 1 },
    address_$N: { tag: "dp_", tagGroupIndex: 1 },
    occupation_type_$N: {
      tag: "dp_",
      tagGroupIndex: 1,
    },
    workplace_$N: { tag: "dp_", tagGroupIndex: 1 },
    residence_type_$N: {
      tag: "dp_",
      tagGroupIndex: 1,
    },
    is_living_together_$N: { type: "boolean", tag: "dp_", tagGroupIndex: 1 },
    income_$N: {
      tag: "dp_",
      tagGroupIndex: 1,
    },
    dependent_type_$N: {
      tag: "dp_",
      tagGroupIndex: 1,
    },
    tax_dependent_type_$N: {
      tag: "dp_",
      tagGroupIndex: 1,
    },
    tax_dependent_on_$N: { tag: "dp_", tagGroupIndex: 1 },
    loss_of_tax_dependent_on_$N: {
      tag: "dp_",
      tagGroupIndex: 1,
    },
    disability_type_$N: {
      tag: "dp_",
      tagGroupIndex: 1,
    },
    relation_file_id_$N: { tag: "dp_", tagGroupIndex: 1 },
    disability_description_$N: { tag: "dp_", tagGroupIndex: 1 },
    disability_certificate_on_$N: { tag: "dp_", tagGroupIndex: 1 },
    disability_certificate_type_$N: {
      tag: "dp_",
      tagGroupIndex: 1,
    },
    insurance_dependent_type_$N: {
      tag: "dp_",
      tagGroupIndex: 1,
    },
    insurance_dependent_on_$N: { tag: "dp_", tagGroupIndex: 1 },
    insurance_dependent_on_reason_type_$N: {
      tag: "dp_",
      tagGroupIndex: 1,
    },
    insurance_dependent_on_other_reason_$N: {
      tag: "dp_",
      tagGroupIndex: 1,
    },
    loss_of_insurance_dependent_on_$N: {
      tag: "dp_",
      tagGroupIndex: 1,
    },
    insurance_dependent_loss_reason_type_$N: {
      tag: "dp_",
      tagGroupIndex: 1,
    },
    insurance_dependent_loss_other_reason_$N: {
      tag: "dp_",
      tagGroupIndex: 1,
    },
    family_benefit_$N: { tag: "dp_", tagGroupIndex: 1 },
    death_on_$N: { tag: "dp_", tagGroupIndex: 1 },
    remarks_$N: { tag: "dp_", tagGroupIndex: 1 },
  },
  labelMap: {
    ja: {
      dependent: "家族",
    },
  },
};
const data = {
  ...base,
  validateSubFields: (allSubFields: ProfileSubField[]) => {
    // 表示されている ProfileSubField に対して通常のバリデーションを行う
    const tagHandlerIndex = allSubFields.findIndex((_) => _.id === "dependent");
    const tagHandler = allSubFields[tagHandlerIndex];
    const tagGroupsToUse = tagHandler.tagGroupsToUse;
    const correspondingProfileFieldSubFields = allSubFields
      .filter((_) => _.type !== "tagHandler")
      .filter((_) => _.tag === tagHandler.tag)
      .filter((_) => tagGroupsToUse.includes(_.tagGroupIndex));

    const tagGroupIndex = correspondingProfileFieldSubFields?.[0].tagGroupIndex ?? 1;
    const subFieldData = Object.keys(base.subFields).reduce((prev, current) => {
      const _fieldName = current.replace("_$N", `_${tagGroupIndex}`);
      const _subField = correspondingProfileFieldSubFields.find(({ id }) => id === _fieldName);
      return { ...prev, [current]: { type: _subField?.type } };
    }, {} as { [subFieldName: string]: any });
    const { validated, subFields } = generateValidator(subFieldData)(correspondingProfileFieldSubFields);
    return {
      validated,
      subFields: allSubFields.map((sf) => {
        const correspondingSubField = subFields.find((_) => _.id === sf.id);
        if (!correspondingSubField) return sf;
        const _sf = { ...sf, errorMessage: correspondingSubField.errorMessage };
        return _sf;
      }),
    };
  },
  toProfileFields: (responseResults: { [k: string]: any }[], columns?: RegularColumn[]): ProfileField[] => {
    if (responseResults.length > 0) {
      return generateMultipleProfileFieldsBundler("dependent", base, "tagHandler", "dp_")(responseResults, columns);
    } else {
      // レスポンスがなくても新規入力可能な状態にするため、ProfileField を返す
      const r = toProfileField({ fieldName: "dependent", base, columns });
      return [r];
    }
  },
  toDiffList: (subFields: ProfileSubField[], validDateMap: ValidDateMap) => {
    const map = diffCollector(subFields, validDateMap);
    /*
     置換例
    
      [
        {
          fieldName: "dependent_1_name",
          value: "name 1",
          recordId: 1
        },
        {
          fieldName: "dependent_2_name",
          value: "name 2",
          recordId: 2
        }
      ]

      ↓

      [
        {
          fieldName: "dependent_name",
          value: "name 1",
          recordId: 1
        },
        {
          fieldName: "dependent_name",
          value: "name 2",
          recordId: 2
        }
      ]
    */
    const modified = map.map((data) => {
      let _subFieldName = data.subFieldName;
      if (/([a-z]+)_([1-9]+)/.test(data.subFieldName)) {
        _subFieldName = data.subFieldName.replace(/([a-z]+)_([1-9]+)/, "$1");
      } else if (/([a-z]+)_([1-9]+)_([a-z]+)/.test(data.subFieldName))
        _subFieldName = data.subFieldName.replace(/([a-z]+)_([1-9]+)_([a-z]+)/, "$1_$3");
      return {
        ...data,
        subFieldName: _subFieldName,
      };
    });
    return modified;
  },
};

export default data;
