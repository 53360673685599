const data = {
  category: "personal_classification",
  table: "personal_classification",
  subFields: {
    personal_classification_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    taxation_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    widow_single_parent_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    disability_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relation_file_id_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    disability_description_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    disability_certificate_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    disability_certificate_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    foreigner_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    resident_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    legal_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    legal_last_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    legal_first_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    legal_name_kana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    legal_last_name_kana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    legal_first_name_kana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    remarks_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      personal_classification_history: "本人区分履歴",
    },
  },
};

export default data;
