import { RegularColumn } from "../../client/clientValues";
import { ProfileField, ProfileSubField, generateProfileFieldsBundler, generateValidator } from "../profileFieldValues";

const base = {
  category: "transportation_allowance",
  table: "transportation_allowance",
  subFields: {
    nearest_station: {},
    duration: {},
    transportation: {
      type: "tagHandler",
      tag: "tp_",
      minTagGroupsLength: 1,
      maxTagGroupsLength: 3,
    },
    ...(() => {
      let _ = {};
      [1, 2, 3].forEach((i) => {
        _ = {
          ..._,
          [`transportation_${i}_name`]: {
            required: true,
            tag: "tp_",
            tagGroupIndex: i,
          },
          [`transportation_${i}_start_segment`]: {
            required: true,
            tag: "tp_",
            tagGroupIndex: i,
          },
          [`transportation_${i}_end_segment`]: {
            required: true,
            tag: "tp_",
            tagGroupIndex: i,
          },
          [`transportation_${i}_non_taxable_commuting_expense`]: {
            tag: "tp_",
            tagGroupIndex: i,
          },
          [`transportation_${i}_taxable_commuting_expense`]: {
            tag: "tp_",
            tagGroupIndex: i,
          },
          [`transportation_${i}_payment_amount`]: {
            tag: "tp_",
            tagGroupIndex: i,
          },
          [`transportation_${i}_interval_type`]: {
            tag: "tp_",
            tagGroupIndex: i,
          },
          [`transportation_${i}_payment_type`]: {
            tag: "tp_",
            tagGroupIndex: i,
          },
          [`transportation_${i}_payment_start_on`]: {
            tag: "tp_",
            tagGroupIndex: i,
          },
          [`transportation_${i}_means_type`]: {
            tag: "tp_",
            tagGroupIndex: i,
          },
          [`commutation_allowance_${i}_one`]: {
            tag: "tp_",
            tagGroupIndex: i,
          },
          [`commutation_allowance_${i}_three`]: {
            tag: "tp_",
            tagGroupIndex: i,
          },
          [`commutation_allowance_${i}_six`]: {
            tag: "tp_",
            tagGroupIndex: i,
          },
          [`one_way_fare${i}`]: {
            tag: "tp_",
            tagGroupIndex: i,
          },
        };
      });
      return _;
    })(),
    means_type: {},
    distance: {},
    car_etc_non_taxable_commuting_expense: {},
    car_etc_taxable_commuting_expense: {},
    allowance_for_car_etc: {},
    car_etc_interval_type: {},
    car_etc_payment_type: {},
    car_etc_payment_start_on: {},
    car_etc_allowance_one: {},
    car_etc_allowance_three: {},
    car_etc_allowance_six: {},
    adjustment_support_starting_month: {},
    adjustment_payment_period_type: {},
    adjustment_payment_method_type: {},
    adjustment_allowance: {},
    tax_free_commuting_allowance: {},
    taxable_commuting_allowance: {},
    tax_free_commuting_allowance_amount_paid: {},
    taxable_commuting_allowance_amount_paid: {},
  },
  labelMap: {
    ja: {
      transportation: "通勤経路",
    },
  },
};

const data = {
  ...base,
  validateSubFields: (allSubFields: ProfileSubField[]) => {
    // 表示されている ProfileSubField に対して通常のバリデーションを行う
    const tagHandlerIndex = allSubFields.findIndex((_) => _.id === "transportation");
    const tagHandler = allSubFields[tagHandlerIndex];
    const tagGroupsToUse = tagHandler.tagGroupsToUse;
    // 繰り返し項目で、使用中のsubField
    const involvedSubFields = allSubFields
      .filter((_) => _.type !== "tagHandler")
      .filter((_) => _.tag === tagHandler.tag)
      .filter((_) => tagGroupsToUse.includes(_.tagGroupIndex));
    // 繰り返し項目ではないsubField
    const simpleSubFields = allSubFields.filter((_) => _.type !== "tagHandler" && _.tag !== tagHandler.tag);
    // 項目とtypeをマッピング
    const subFieldData = Object.keys(base.subFields).reduce((prev, current) => {
      const _simpleSubField = simpleSubFields.find(({ id }) => id === current);
      if (_simpleSubField) return { ...prev, [current]: { type: _simpleSubField.type } };
      const _involvedSubField = involvedSubFields.find(({ id }) => id === current.replace("_$N", "_1"));
      if (_involvedSubField) return { ...prev, [current]: { type: _involvedSubField.type } };
      return prev;
    }, {} as { [subFieldName: string]: any });
    const { validated, subFields } = generateValidator(subFieldData)([...involvedSubFields, ...simpleSubFields]);
    // ProfileField 同士の関係性をふまえたバリデーション（例：開始日・終了日の順序）はここに追加していく
    return {
      validated,
      subFields: allSubFields.map((sf) => {
        const correspondingSubField = subFields.find((_) => _.id === sf.id);
        // バリデートしなかった=表示されていない項目のエラーメッセージはクリアする
        if (!correspondingSubField) return { ...sf, errorMessage: "" };
        const _sf = { ...sf, errorMessage: correspondingSubField.errorMessage };
        return _sf;
      }),
    };
  },
  toProfileFields: (responseResults: { [k: string]: any }[], columns?: RegularColumn[]): ProfileField[] => {
    // 通勤経路 1, 2, 3 のどこまで表示するかを設定
    const profileFields = generateProfileFieldsBundler("transportation_allowance", base)(responseResults, columns);
    return profileFields.map((profileField) => {
      const tagHandlerIndex = profileField.subFields.findIndex((_) => _.id === "transportation");
      const tagHandler = profileField.subFields[tagHandlerIndex];
      const tagGroupsToUse: number[] = [];
      [1, 2, 3].forEach((index) => {
        // ここでグループを表示するかしないかを判定する
        const n = profileField.subFields.find((_) => _.id === `transportation_${index}_name`)!;
        if (n.value) tagGroupsToUse.push(index);
      });
      const _tagHandler = { ...tagHandler, tagGroupsToUse };
      return {
        ...profileField,
        subFields: profileField.subFields.map((f, i) => (i !== tagHandlerIndex ? f : _tagHandler)),
      };
    });
  },
};

export default data;
