import { useState, useEffect } from "react";
import { Row, Col, Accordion, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Account, ACCOUNT_SEARCH_LIMIT } from "../../features/profile/profileValues";
import { Section } from "../section/sectionSlice";

function MemberSelector({
  accounts,
  sections,
  defaultAccountId,
  onSelectedAccountChange,
  onKeywordChange,
}: {
  accounts: Account[];
  sections: Section[];
  defaultAccountId?: number;
  onSelectedAccountChange: (id: number) => any;
  onKeywordChange: (keyword: string) => any;
}) {
  const [state, $state] = useState({
    keyword: "",
    checkedsectionCodes: [] as string[],
    checkedAccountId: 0,
    timeoutId: null as number | null,
  });

  const selectTarget = (id: number) => {
    $state({ ...state, checkedAccountId: id });
    onSelectedAccountChange(id);
  };

  const onChangeKeyWord = (keyword: string) => {
    // 打ち終わって500ms後に検索のリクエストをする
    if (state.timeoutId) {
      window.clearTimeout(state.timeoutId);
    }
    const timeoutId = window.setTimeout(() => {
      typeof onKeywordChange === "function" && onKeywordChange(keyword);
      $state({ ...state, timeoutId: null, keyword });
    }, 500);
    $state({ ...state, timeoutId, keyword });
  };

  const checkedMember = accounts.find((t) => state.checkedAccountId === t.id);
  useEffect(() => {
    if (defaultAccountId && accounts.find((a) => a.id === defaultAccountId)) {
      $state({ ...state, checkedAccountId: defaultAccountId });
      onSelectedAccountChange(defaultAccountId);
    }
  }, [defaultAccountId, accounts]);

  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>{checkedMember ? checkedMember.name : "（未選択）"}</Accordion.Header>
        <Accordion.Body>
          <Row className="mb-2">
            <Col md="8" className="mt-4 mt-md-0">
              <div className="--bold mb-2">対象者</div>

              <Form.Control
                type="text"
                id="search"
                value={state.keyword}
                className="mb-2"
                placeholder="キーワード（名前・ログインID）で絞り込む"
                onChange={(e) => {
                  onChangeKeyWord(e.target.value);
                }}
              />
              <Row>
                {accounts.map((t) => (
                  <Col md="6" key={t.id}>
                    <Form.Check
                      checked={state.checkedAccountId === t.id}
                      id={`${t.id}`}
                      type="radio"
                      label={`${t.name}（${t.loginCode}）${!t.isActive ? "（無効）" : ""}`}
                      onChange={() => selectTarget(t.id)}
                    />
                  </Col>
                ))}
                {accounts.length === ACCOUNT_SEARCH_LIMIT && (
                  <Col>{`${ACCOUNT_SEARCH_LIMIT}名以上存在します。選択したいアカウントが表示されていない場合はキーワードを変更してください。`}</Col>
                )}
                {accounts.length === 0 && <Col>該当するアカウントが存在しません。キーワードを変更してください。</Col>}
              </Row>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default MemberSelector;
