const data = {
  category: "spouse",
  table: "spouse",
  subFields: {
    spouse_name: {},
    spouse_name_kana: {},
    gender_type: {},
    relationship_type: {},
    birthday: {},
    age: {},
    marriage_on: {},
    postcode: {},
    address: {},
    occupation_type: {},
    workplace: {},
    residence_type: {},
    is_living_together: { default: false },
    income: {},
    dependent_type: {},
    tax_dependent_type: {},
    tax_dependent_on: {},
    loss_of_tax_dependent_on: {},
    disability_type: {},
    relation_file_id: {},
    disability_description: {},
    disability_certificate_on: {},
    disability_certificate_type: {},
    insurance_dependent_type: {},
    insurance_dependent_on: {},
    insurance_dependent_on_reason_type: {},
    insurance_dependent_on_other_reason: {},
    loss_of_insurance_dependent_on: {},
    insurance_dependent_loss_reason_type: {},
    insurance_dependent_loss_other_reason: {},
    family_benefit: {},
    death_on: {},
    remarks: {},
  },
};
export default data;
